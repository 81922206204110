import React, { useEffect, useMemo } from "react";

import { useHistory } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import { getVisits } from "../../actions/VisitsActions";

import Button from "../../components/Button";
import VisitItem from "../../components/VisitItem";
import { Tab, Tabs } from "../../components/Tabs";
import UserPanelLayout from "../../layouts/userPanel";

const Visits = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const visits = useSelector((state) => state.visits);

  useEffect(() => {
    dispatch(getVisits());
  }, [dispatch]);

  const today = new Date();

  const upcomingVisits = useMemo(() => {
    return (
      visits?.data?.filter((visit) => new Date(visit?.start) > today) || []
    );
  }, [visits, today]);

  const archivalVisits = useMemo(() => {
    return (
      visits?.data?.filter((visit) => new Date(visit?.start) < today) || []
    );
  }, [visits, today]);

  const loading = visits.loading;

  return (
    <UserPanelLayout>
      <div className="head">
        <h2 className="title">Wizyty</h2>
        <Button onClick={() => history.push("/placowki")}>Nowa wizyta</Button>
      </div>
      {visits?.visits?.length < 1 ? (
        <span className="no-data">Brak wizyt</span>
      ) : (
        <Tabs>
          <Tab title="Nadchodzące">
            {upcomingVisits?.length > 0 ? (
              upcomingVisits.map((visit) => {
                return (
                  <VisitItem loading={loading} key={visit.id} data={visit} />
                );
              })
            ) : (
              <span className="no-data">Brak nadchodzących wizyt</span>
            )}
          </Tab>

          {archivalVisits?.length > 0 && (
            <Tab title="Archiwalne">
              {archivalVisits?.length > 0 ? (
                archivalVisits.map((visit) => {
                  return (
                    <VisitItem
                      loading={loading}
                      key={visit.id}
                      archival
                      data={visit}
                    />
                  );
                })
              ) : (
                <span className="no-data">Brak archiwalnych wizyt</span>
              )}
            </Tab>
          )}
        </Tabs>
      )}
    </UserPanelLayout>
  );
};

export default Visits;
