import API from "./ApiConfig";

export const productsApi = {
  getTypesOfProducts,
  getAllProducts,
  getProductsByType,
  getProductById,
  getFeaturedProducts,
};

function getTypesOfProducts() {
  return API.get("products-types");
}
//products?_sort=created_at:DESC&_limit=10
function getAllProducts(field, order) {
  return API.get("products", {
    params: {
      _sort: field + ":" + order,
    },
  });
}

function getFeaturedProducts(field, order) {
  return API.get("featured-products-sections", {
    params: {
      _sort: field + ":" + order,
    },
  });
}

function getProductsByType(field, order, limit, typeID) {
  return API.get("products", {
    params: {
      "_sort": field + ":" + order,
      "_limit": limit,
      "type.id": typeID,
    },
  });
}

function getProductById(id) {
  return API.get(`products/${id}`);
}
