import { productsApi } from "../api/ProductsApi";
import { errorParser } from "../api/ErrorParser";
import * as types from "./ProductsConstants";

export const fetchProducts = () => ({
  type: types.FETCH_PRODUCTS,
});

export const fetchFeaturedProducts = () => ({
  type: types.FETCH_FEATURED_PRODUCTS,
});

export const fetchProduct = (id) => ({
  type: types.FETCH_PRODUCT,
  id: id,
});

export const fetchTypes = () => ({
  type: types.FETCH_TYPES_PRODUCTS,
});

export const saveProducts = (data) => ({
  type: types.FETCH_PRODUCTS_SUCCESS,
  payload: data,
});

export const saveFeaturedProducts = (data) => ({
  type: types.FETCH_FEATURED_PRODUCTS_SUCCESS,
  payload: data,
});

export const saveProduct = (data) => ({
  type: types.FETCH_PRODUCT_SUCCESS,
  payload: data,
});

export const saveTypes = (data) => ({
  type: types.FETCH_TYPES_PRODUCTS_SUCCESS,
  payload: data,
});

export const errorProducts = (errorMessage) => ({
  type: types.FETCH_PRODUCTS_FAILURE,
  payload: errorMessage,
});

export const errorProduct = (errorMessage, id) => ({
  type: types.FETCH_PRODUCT_FAILURE,
  payload: errorMessage,
  id: id,
});

export const errorTypes = (errorMessage) => ({
  type: types.FETCH_TYPES_PRODUCTS_FAILURE,
  payload: errorMessage,
});

export const getProducts = (field = "created_at", order = "DESC") => (
  dispatch,
) => {
  dispatch(fetchProducts());
  productsApi
    .getAllProducts(field, order)
    .then(async (res) => {
      dispatch(saveProducts(res.data));
    })
    .catch((err) => {
      dispatch(errorProducts(errorParser.parseError(err).message));
    });
};

export const getFeaturedProducts = (field = "created_at", order = "DESC") => (
  dispatch,
) => {
  dispatch(fetchFeaturedProducts());
  productsApi
    .getFeaturedProducts(field, order)
    .then(async (res) => {
      dispatch(saveFeaturedProducts(res.data));
    })
    .catch((err) => {
      dispatch(errorProducts(errorParser.parseError(err).message));
    });
};

export const getProductsByType = (
  type,
  field = "created_at",
  order = "DESC",
  limit = 10,
) => (dispatch) => {
  dispatch(fetchProducts());
  productsApi
    .getProductsByType(field, order, limit, type)
    .then(async (res) => {
      dispatch(saveProducts(res.data));
    })
    .catch((err) => {
      dispatch(errorProducts(errorParser.parseError(err).message));
    });
};

export const getTypes = () => (dispatch) => {
  dispatch(fetchTypes());
  productsApi
    .getTypesOfProducts()
    .then(async (res) => {
      dispatch(saveTypes(res.data));
    })
    .catch((err) => {
      dispatch(errorTypes(errorParser.parseError(err).message));
    });
};

export const getProductById = (id) => (dispatch) => {
  dispatch(fetchProduct(id));
  productsApi
    .getProductById(id)
    .then(async (res) => {
      dispatch(saveProduct(res.data));
    })
    .catch((err) => {
      dispatch(errorProduct(errorParser.parseError(err).message, id));
    });
};
