import { strings } from "../values/Strings";

function parseError(err) {
  console.log("Error: " + err);

  let parsedError = { message: "Request error" };
  if (err.response) {
    parsedError = {
      ...parsedError,
      message: strings[`error_${err.response.status}`] || err.response.status,
    };
  }

  return parsedError;
}

export const errorParser = {
  parseError,
};
