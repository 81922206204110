import React from "react";
import { LightgalleryProvider } from "react-lightgallery";
import PhotoItem from "./photo";
import { array } from "prop-types";
import "lightgallery.js/dist/css/lightgallery.css";

const Gallery = ({ data }) => {
  return (
    <LightgalleryProvider>
      <div className="row">
        {data.map((p, idx) => (
          <PhotoItem key={idx} image={p} group="clinic" />
        ))}
      </div>
    </LightgalleryProvider>
  );
};

export default Gallery;

Gallery.propTypes = {
  data: array,
};
