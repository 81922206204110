import React, { useEffect, useMemo, useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import * as productsActions from "../../actions/ProductsActions";
import * as petTypesActions from "../../actions/PetsActions";

import ProductItem from "../../components/ProductItem";
import Loader from "react-loader-spinner";

const ProductsPage = () => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products);

  const { productsList, typesList, typesLoading, productsLoading } = products;

  const [mainCat, setMainCat] = useState("");
  const [secondCat, setSecondCat] = useState("");

  const currentProducts = useMemo(() => {
    if (mainCat?.id) {
      return productsList?.filter((product) => {
        return (
          product?.type?.parent === mainCat?.id &&
          (product?.type?.id === secondCat?.id || !secondCat?.id)
        );
      });
    }
    return productsList;
  }, [productsList, mainCat, secondCat]);

  useEffect(() => {
    dispatch(productsActions.getProducts());
    dispatch(productsActions.getTypes());
    dispatch(petTypesActions.getPetsTypes());
  }, [dispatch]);

  const mainCategories = typesList?.filter(
    (type) => type?.children?.length > 0 && !type?.parent
  );

  const loading = typesLoading || productsLoading;

  return (
    <div className="products-page">
      <div className="container">
        <h1>Produkty dla pupili</h1>

        {loading ? (
          <div className="loader-wrapper loader-wrapper--transparent">
            <Loader type="Oval" color="#f66829" height={100} width={100} />
          </div>
        ) : (
          <div className="row">
            <div className="col-5">
              <aside>
                <ul>
                  {mainCategories?.map((mCat, index) => {
                    return (
                      <li key={index}>
                        <span
                          onClick={() => {
                            setMainCat(mCat);
                            setSecondCat("");
                          }}
                          className="list-title"
                        >
                          {mCat.type}
                        </span>
                        <ul>
                          {mCat?.children?.map((sCat, index) => {
                            return (
                              <li
                                key={index}
                                className={
                                  mCat?.id === mainCat.id &&
                                  sCat?.id === secondCat?.id
                                    ? "active"
                                    : ""
                                }
                                onClick={() => {
                                  setMainCat(mCat);
                                  setSecondCat(sCat);
                                }}
                              >
                                {sCat.type}
                              </li>
                            );
                          })}
                        </ul>
                      </li>
                    );
                  })}
                </ul>
              </aside>
            </div>

            <div className="col-19">
              <div className="head">
                <h2 className="title">
                  <span>{mainCat?.type || "Wszystkie"}</span>
                  {secondCat?.type && (
                    <>
                      {" > "}
                      <span>{secondCat.type}</span>
                    </>
                  )}
                </h2>
              </div>

              <div className="row">
                {currentProducts?.length > 0 ? (
                  currentProducts?.map((item, index) => (
                    <ProductItem col="6" data={item} key={index} />
                  ))
                ) : (
                  <span className="products-page__no-results">
                    Brak produktów dla wybranej kategorii.
                  </span>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductsPage;
