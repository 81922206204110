import * as actions from "../actions/VisitsConstants";

const initialState = {
  data: [],
  loading: false,
  errorMessage: "",
  byId: {},
  postopne: false,
};

const visitsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_VISITS:
    case actions.FETCH_ALL_DOCTOR_VISITS:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case actions.FETCH_VISITS_FAILURE:
    case actions.FETCH_ALL_DOCTOR_VISITS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actions.FETCH_VISITS_SUCCESS:
    case actions.FETCH_ALL_DOCTOR_VISITS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: "",
      };

    case actions.UPDATE_VISIT_COMMENT:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case actions.UPDATE_VISIT_COMMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case actions.UPDATE_VISIT_COMMENT_SUCCESS:
      return {
        ...state,
        data: [...state.data].map((item, index) => {
          if (item.id === action.payload.id) {
            return action.payload;
          }
          return item;
        }),
        loading: false,
        error: "",
      };

    case actions.FETCH_VISIT:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.id]: state.byId[action.id]
            ? { ...state.byId[action.id], loading: true, error: "" }
            : { loading: true, error: "", data: {} },
        },
      };

    case actions.FETCH_VISIT_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...state.byId[action.payload.id],
            data: action.payload,
            loading: false,
            error: "",
          },
        },
      };
    case actions.FETCH_VISIT_FAILURE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...state.byId[action.payload.id],
            loading: false,
            error: action.payload,
          },
        },
      };

    case actions.DELETE_VISIT:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case actions.DELETE_VISIT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        data: state.data.filter((visit) => visit.id !== action.payload.id),
      };
    case actions.DELETE_VISIT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case actions.RESERVE_VISIT:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case actions.RESERVE_VISIT_RESET:
      return {
        ...state,
        loading: false,
        error: "",
        success: false,
      };

    case actions.RESERVE_VISIT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: true,
        data: [...state.data, action.payload],
      };

    case actions.RESERVE_VISIT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default visitsReducer;
