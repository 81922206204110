import React from "react";
import "react-calendar/dist/Calendar.css";
import { func, instanceOf } from "prop-types";
import Calendar from "react-calendar";

const MyCalendar = ({ onDateSelect, selectedDate }) => {
  const onDateChange = (nDate) => {
    onDateSelect(nDate);
  };

  return (
    <div className="calendar-wrapper">
      <Calendar
        minDate={new Date()}
        onChange={(d) => onDateChange(d)}
        value={selectedDate}
      />
    </div>
  );
};

export default MyCalendar;

MyCalendar.propTypes = {
  onDateSelect: func.isRequired,
  selectedDate: instanceOf(Date),
};
