import React, { useState } from "react";
import {
  arrayOf,
  shape,
  number,
  string,
  func,
  bool,
  oneOfType,
} from "prop-types";
import Icon from "../../Icon";

const RadioInput = ({
  name,
  options,
  onInputChange,
  label,
  withImages = false,
  iconPlaceholder = "person",
  defaultValue = null,
  className,
}) => {
  const [checked, setChecked] = useState(options[0].value);

  const [, setCurrentDate] = useState(new Date());

  const onChangeValue = (value) => {
    setCurrentDate(value);
    onInputChange(value);
  };

  return (
    <fieldset
      className={`custom-radios ${className} ${
        withImages ? "custom-radios--with-img" : ""
      }`}
    >
      {label && <span className="label">{label}</span>}
      {options?.map((option, index) => {
        return (
          <label key={index}>
            <input
              onChange={() => {
                setChecked(option.value);
                onChangeValue(option.value);
              }}
              checked={
                defaultValue
                  ? option.value === defaultValue
                  : option.value === checked
              }
              hidden
              value={option.value}
              type="radio"
              name={name}
            />
            {withImages && (
              <>
                {option?.img ? (
                  <img src={option?.img} alt="obrazek wyboru" />
                ) : (
                  <Icon name={iconPlaceholder} />
                )}
              </>
            )}

            <span className="custom-radios__name">{option.name}</span>
          </label>
        );
      })}
    </fieldset>
  );
};

export default RadioInput;

RadioInput.propTypes = {
  name: string.isRequired,
  onInputChange: func.isRequired,
  options: arrayOf(
    shape({
      value: number | string,
      name: string,
    })
  ).isRequired,
  label: string,
  withImages: bool,
  iconPlaceholder: string,
  defaultValue: oneOfType([string, number]),
  className: string,
};
