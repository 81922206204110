import React, { useEffect, useState } from "react";

const calculateTimeLeft = (startDate) => {
  const difference = +new Date(startDate) - +new Date();
  let timeLeft = {};
  if (difference > 0) {
    timeLeft = {
      D: Math.floor(difference / (1000 * 60 * 60 * 24)),
      Godz: Math.floor((difference / (1000 * 60 * 60)) % 24),
      Min: Math.floor((difference / 1000 / 60) % 60),
    };
  }
  return timeLeft;
};

const Countdown = ({ startDate }) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(startDate));

  useEffect(() => {
    const timer = setTimeout(
      () => setTimeLeft(calculateTimeLeft(startDate)),
      60000
    );
    return () => clearTimeout(timer);
  });

  let timerComponents = Object.keys(timeLeft)
    .filter((interval) => timeLeft[interval])
    .map((interval) => (
      <span key={interval}>
        {timeLeft[interval]} {interval}{" "}
      </span>
    ));

  return (
    <strong>
      {timerComponents.length ? timerComponents : "TO DO - do uzgodnienia"}
    </strong>
  );
};

export default Countdown;
