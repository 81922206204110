import React, { useState, useContext, useEffect } from "react";
import { RemindPasswordContext } from "../../contexts/modals";
import Modal from "react-modal";
import Button from "../Button";
import Icon from "../Icon";
import InputComponent from "../Input";
import { strings } from "../../values/Strings";
import { useDispatch } from "react-redux";
import { resetPassword } from "../../actions/UserActions";
import { useSelector } from "react-redux";
import { validatePassword } from "../../utils/utils";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

const SetNewPasswordModal = ({ qData }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { setShowRemindPasswordModal } = useContext(RemindPasswordContext);

  const history = useHistory();
  const [data, setData] = useState({
    password: "",
    passwordConfirmation: "",
  });
  const [error, setError] = useState("");

  const remindPasswordHandler = () => {
    setError("");
    if (data.password.length === 0 && data.passwordConfirmation.length === 0) {
      setError(strings.error_fields_empty);
      return;
    }

    if (data.password !== data.passwordConfirmation) {
      setError(strings.error_pass_not_match);
      return;
    }

    if (!validatePassword(data.password)) {
      setError(strings.error_pass_validations);
      return;
    }

    if (!validatePassword(data.passwordConfirmation)) {
      setError(strings.error_pass_validations);
      return;
    }

    dispatch(
      resetPassword(qData.code, data.password, data.passwordConfirmation)
    );
    setShowRemindPasswordModal(false);
    history.push("/");
  };

  useEffect(() => {
    if (auth.errorMessage.length) {
      setError(auth.errorMessage);
    }
  }, [dispatch, auth]);

  const handleClick = () => {
    setShowRemindPasswordModal(false);
    history.push("/");
  };

  return (
    <Modal
      isOpen={true}
      className="modal modal--auth set-new-password"
      overlayClassName="modal-overlay"
      ariaHideApp={false}
      onRequestClose={() => setShowRemindPasswordModal(false)}
    >
      <div className="modal-Header">
        <h2>Przypomnij hasło</h2>
        <Button onClick={handleClick} className="transparent padding-0">
          <Icon name="close" color="white" />
        </Button>
      </div>

      <div className="modal-content">
        <InputComponent
          placeholder="Nowe hasło"
          value={data.password}
          onChange={(e) =>
            setData({
              ...data,
              password: e.target.value,
            })
          }
        />
        <InputComponent
          placeholder="Powtórz nowe hasł"
          value={data.passwordConfirmation}
          onChange={(e) =>
            setData({
              ...data,
              passwordConfirmation: e.target.value,
            })
          }
        />
        {error && <span className="modal-form__error">{error}</span>}
        <Button className="send-btn" onClick={remindPasswordHandler}>
          Wyślij
        </Button>
      </div>
    </Modal>
  );
};

export default SetNewPasswordModal;

SetNewPasswordModal.propTypes = {
  qData: PropTypes.exact({ code: PropTypes.string }).isRequired,
};
