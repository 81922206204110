import axios from "axios";
import store from "../store/ConfigureStore";
import strings from "../values/Strings";
const API_URL = strings.API_URL;

const API = axios.create({
  baseURL: API_URL,
});

API.interceptors.request.use((config) => {
  const token = store.getState().auth.token || localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default API;
