import React, { useEffect } from "react";

import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import * as actions from "../../actions/ArticlesActions";

import Loader from "react-loader-spinner";
import Button from "../../components/Button";

import { getUrlToImage } from "../../utils/utils";
import { strings } from "../../values/Strings";

import moment from "moment";

const ArticlePage = () => {
  const dispatch = useDispatch();
  const articles = useSelector((state) => state.articles);

  const history = useHistory();
  const { id } = useParams();

  const data = articles?.byId[id];

  useEffect(() => {
    if (id && !data?.id && !data?.loading) {
      dispatch(actions.getArticleById(id));
    }
  }, [data, articles, id, dispatch]);

  if (data?.loading) {
    return (
      <div className="loader-wrapper">
        <Loader type="Oval" color="#f66829" height={100} width={100} />
      </div>
    );
  }

  return (
    <div className="simple-page">
      <div className="container">
        <div className="row">
          <div className="col-24">
            <Button
              icon="arrow_down"
              iconColor="orange-light"
              className="transparent"
              onClick={() => history.goBack()}
            >
              Wróć
            </Button>
          </div>
          <div className="col-12">
            <div className="simple-page__left">
              <h2>{data?.title}</h2>
              <time>
                &bull; Dodano{" "}
                {moment(data?.updated_at).format(strings.full_date)}
              </time>
              {data?.content && (
                <article>
                  <p>{data.content}</p>
                </article>
              )}
            </div>
          </div>
          <div className="col-12">
            <div className="simple-page__right simple-page__right--borderless">
              <picture>
                <img
                  src={getUrlToImage(data?.cover[0]?.url)}
                  alt={data?.name || "produkt"}
                />
              </picture>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticlePage;
