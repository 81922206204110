import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import Icon from "../Icon";
import Button from "../Button";
import ConfirmModal from "../Modals/ConfirmModal";

import { getAvatar } from "../../utils/utils";
import {
  deletePet,
  getPetHistory,
  getPetHistoryStart,
} from "../../actions/PetsActions";

import PropTypes from "prop-types";

const Pet = ({ data, getPet, openModal, userId }) => {
  const dispatch = useDispatch();
  const aRef = useRef(null);

  const pets = useSelector((state) => state.pets);
  const petHistoryLink = pets?.petHistoryLink;

  const [open, setOpen] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const {
    id,
    name,
    mikrochipId,
    pet_type,
    sex,
    weight,
    neutered,
    outgoing,
    origin,
    yearBirth,
  } = data;

  useEffect(() => {
    petHistoryLink && aRef.current.click();
    petHistoryLink && dispatch(getPetHistoryStart());
  }, [petHistoryLink, dispatch]);

  const handleClick = () => {
    getPet(data);
    openModal();
  };

  const handleConfirm = () => {
    dispatch(deletePet(id, userId));
    setShowConfirmModal(false);
  };

  return (
    <>
      <div className="pet-component">
        <div className="head row no-gutters" onClick={() => setOpen(!open)}>
          <img className="pet-img" src={getAvatar(data)} alt="pupil" />
          <div className="basic-data">
            <h3>{name}</h3>
            <p>{pet_type?.type ? pet_type.type : "Brak danych"}</p>
            <Icon
              name={
                sex === "female" ? "female_icon" : sex === "male" && "male_icon"
              }
              color="orange"
            />
          </div>
          <Button
            className="transparent"
            onClick={() => dispatch(getPetHistory(id))}
          >
            Pobierz historię wizyt pupila
          </Button>
          <a
            ref={aRef}
            rel="noopener noreferrer"
            target="_blank"
            href={`${pets.petHistoryLink}`}
            download
          >
            <></>
          </a>
        </div>

        <div className={`row no-gutters flex-nowrap${open ? " open" : ""}`}>
          <div className="details">
            <div className="chip-pesel">
              <h5>Nr mikroczip / PESEL</h5>
              <span>{mikrochipId}</span>
            </div>
            <div className="weight">
              <h5>Waga pupila</h5>
              <span>{weight}</span>
            </div>
            <div className="castration">
              <h5>Wykastrowany</h5>
              <span>{neutered ? "Tak" : "Nie"}</span>
            </div>
            <div className="birthday">
              <h5>Rok urodzenia</h5> <span>{yearBirth}</span>
            </div>
            <div className="origin">
              <h5>Pochodzenie</h5> <span>{origin}</span>
            </div>
            <div className="exit">
              <h5>Wychodzący</h5> <span>{outgoing ? "Tak" : "Nie"}</span>
            </div>
          </div>
          <div className="actions row flex-column no-gutters">
            <Button className="transparent" onClick={handleClick}>
              Edytuj dane
            </Button>
            <Button
              className="transparent greyish"
              onClick={() => setShowConfirmModal(true)}
            >
              Usuń dane
            </Button>
          </div>
        </div>
      </div>

      {showConfirmModal && (
        <ConfirmModal
          question={"Na pewno chcesz usunąć pupila?"}
          hideModal={() => setShowConfirmModal(false)}
          onSubmit={handleConfirm}
        />
      )}
    </>
  );
};

Pet.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    mikrochipId: PropTypes.string.isRequired,
    sex: PropTypes.string.isRequired,
    weight: PropTypes.number.isRequired,
    neutered: PropTypes.bool.isRequired,
    outgoing: PropTypes.bool.isRequired,
    origin: PropTypes.string.isRequired,
    yearBirth: PropTypes.number.isRequired,
    avatar: PropTypes.object,
  }),
  getPet: PropTypes.func,
  openModal: PropTypes.func,
};

export default Pet;
