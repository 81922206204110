export const FETCH_DOCTORS = "FETCH_DOCTORS";
export const FETCH_DOCTORS_SUCCESS = "FETCH_DOCTORS_SUCCESS";
export const FETCH_DOCTORS_FAILURE = "FETCH_DOCTORS_ERROR";

export const FETCH_DOCTOR_TIMESLOTS = "FETCH_DOCTOR_TIMESLOTS";
export const FETCH_DOCTOR_TIMESLOTS_SUCCESS = "FETCH_DOCTOR_TIMESLOTS_SUCCESS";
export const FETCH_DOCTOR_TIMESLOTS_FAILURE = "FETCH_DOCTOR_TIMESLOTS_FAILURE";

export const ADD_DOCTOR = "ADD_DOCTOR";
export const ADD_DOCTOR_SUCCESS = "ADD_DOCTOR_SUCCESS";
export const ADD_DOCTOR_FAILURE = "ADD_DOCTOR_FAILURE";

export const EDIT_DOCTOR = "EDIT_DOCTOR";
export const EDIT_DOCTOR_SUCCESS = "EDIT_DOCTOR_SUCCESS";
export const EDIT_DOCTOR_FAILURE = "EDIT_DOCTOR_FAILURE";

export const DELETE_DOCTOR = "DELETE_DOCTOR";
export const DELETE_DOCTOR_SUCCESS = "DELETE_DOCTOR_SUCCESS";
export const DELETE_DOCTOR_FAILURE = "DELETE_DOCTOR_FAILURE";
