import React, { useEffect, useMemo, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { Marker, Popup } from "react-leaflet";
import L from "leaflet";

import { shape } from "prop-types";

import queryString from "query-string";

const InstanceMarker = ({ data }) => {
  const history = useHistory();
  const hLocation = useLocation();

  const markerRef = useRef();

  const { location, id, name } = data;

  const qData = useMemo(() => {
    return queryString.parse(hLocation?.search);
  }, [hLocation]);

  const selectedPlace = useMemo(() => {
    return qData.place;
  }, [qData]);

  const getIcon = () => {
    return L.icon({
      iconSize: [33, 40],
      iconUrl: "markeroza.svg",
    });
  };

  const active = useMemo(() => {
    return selectedPlace && id === Number(selectedPlace);
  }, [selectedPlace, id]);

  useEffect(() => active && markerRef?.current?.openPopup(), [
    active,
    markerRef,
  ]);

  return (
    <Marker
      ref={markerRef}
      position={location.split(", ")}
      eventHandlers={{
        click: (e) => {
          history?.location?.pathname !== `?place=${id}` &&
            history.push(`?place=${id}`);
          e.target.openPopup();
        },
      }}
      draggable={false}
      icon={getIcon()}
    >
      <Popup>
        <span className="custom-popup">{name}</span>
      </Popup>
    </Marker>
  );
};

export default InstanceMarker;

InstanceMarker.propTypes = {
  data: shape({}),
};
