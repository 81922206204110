import React, { useState } from "react";
import { string, func, bool } from "prop-types";

const CustomSwitch = ({
  name,
  onInputChange,
  textLeft,
  textRight,
  label,
  defaultValue = true,
}) => {
  const [checked, setChecked] = useState(defaultValue);

  const onValueChange = () => {
    setChecked(!checked);
    onInputChange(!checked);
  };

  return (
    <fieldset className="custom-switch">
      {label && <span className="label">{label}</span>}

      <label>
        {textLeft && (
          <span className={`custom-switch__name ${!checked ? "selected" : ""}`}>
            {textLeft}
          </span>
        )}
        <input
          onChange={() => onValueChange(!checked)}
          checked={checked}
          name={name}
          type="checkbox"
        />

        <span className="custom-switch__slider"></span>

        {textRight && (
          <span className={`custom-switch__name ${checked ? "selected" : ""}`}>
            {textRight}
          </span>
        )}
      </label>
    </fieldset>
  );
};

export default CustomSwitch;

CustomSwitch.propTypes = {
  name: string.isRequired,
  onInputChange: func.isRequired,
  textLeft: string,
  textRight: string,
  label: string,
  defaultValue: bool,
};
