import React from "react";

import Button from "../Button";
import Modal from "react-modal";

import { func, bool, string } from "prop-types";

const ConfirmModal = ({ hideModal, onSubmit, loading, question, error }) => {
  return (
    <Modal
      className="modal modal--auth confirm-modal"
      overlayClassName="modal-overlay"
      isOpen={true}
      ariaHideApp={false}
      onRequestClose={hideModal}
    >
      <h4>{question}</h4>
      {error && <span className="modal__error">{error}</span>}
      <div>
        <Button loading={loading} onClick={() => onSubmit()}>
          Tak
        </Button>
        <Button onClick={hideModal}>Nie</Button>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
ConfirmModal.propTypes = {
  hideModal: func,
  onSubmit: func,
  loading: bool,
  question: string,
  error: string,
};
