import React from "react";

import { oneOfType, arrayOf, node, string } from "prop-types";

const HeaderLayout = ({ children, className = "" }) => {
  return (
    <header className={className} id="header">
      <div className="container-fluid">
        <div className="row">{children}</div>
      </div>
    </header>
  );
};

export default HeaderLayout;

HeaderLayout.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  className: string.isRequired,
};
