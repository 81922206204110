import * as actions from "../actions/PlacesConstants";

const initialState = {
  typesList: [],
  placesList: [],
  placesLoading: false,
  typesLoading: false,
  errorMessage: "",
  searchResults: {
    loading: false,
    results: [],
    error: "",
  },
  placeDetails: {
    place: "",
    error: "",
    loading: false,
  },
};

const placesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_TYPES:
      return {
        ...state,
        typesLoading: true,
      };
    case actions.FETCH_PLACES:
      return {
        ...state,
        placesLoading: true,
      };
    case actions.FETCH_PLACES_SUCCESS:
      return {
        ...state,
        placesLoading: false,
        placesList: action.payload,
      };
    case actions.FETCH_TYPES_SUCCESS:
      return {
        ...state,
        typesLoading: false,
        typesList: action.payload,
      };
    case actions.FETCH_TYPES_FAILURE:
      return {
        ...state,
        typesLoading: false,
        errorMessage: action.payload,
      };
    case actions.FETCH_PLACES_FAILURE:
      return {
        ...state,
        placesLoading: false,
        errorMessage: action.payload,
      };
    case actions.SEARCH_PLACES:
      return {
        ...state,
        searchResults: {
          loading: true,
        },
      };
    case actions.SEARCH_PLACES_SUCCESS:
      return {
        ...state,
        searchResults: {
          loading: false,
          results: action.payload,
        },
      };
    case actions.SEARCH_PLACES_FAILURE:
      return {
        ...state,
        searchResults: {
          loading: false,
          error: action.payload,
        },
      };
    case actions.FETCH_PLACE_DETAILS:
      return {
        ...state,
        placeDetails: {
          loading: true,
        },
      };
    case actions.FETCH_PLACE_DETAILS_SUCCESS:
      return {
        ...state,
        placeDetails: {
          place: action.payload,
          error: "",
          loading: false,
        },
      };
    case actions.FETCH_PLACE_DETAILS_FAILURE:
      return {
        ...state,
        placeDetails: {
          place: "",
          error: action.payload,
          loading: false,
        },
      };
    default:
      return state;
  }
};

export default placesReducer;
