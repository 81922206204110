import API from "./ApiConfig";

export const articlesApi = {
  get,
  getSingle,
};

function get() {
  return API.get("articles");
}

function getSingle(id) {
  return API.get(`articles/${id}`);
}
