import React from "react";

import { strings } from "../../values/Strings";
import { getUrlToImage } from "../../utils/utils";

import { Link } from "react-router-dom";

import { shape, string } from "prop-types";

const PlaceItem = ({ item, col }) => {
  const imgSrc = item?.cover?.length > 0 && getUrlToImage(item.cover[0].url);
  return (
    <Link
      to={`/placowki?place=${item?.id}`}
      className={`place-item-wrapper col-${col}`}
    >
      <div className="place-item-wrapper__content ">
        {imgSrc && <img src={imgSrc} alt={item?.name || "brak danych"} />}
        <div>
          <p className="place-item-wrapper__content__place-type">
            {item?.type?.type}
          </p>
          <h4>{item?.name}</h4>
          <p>{item?.address?.city}</p>
          <p>{strings?.prefix_street + item?.address?.street}</p>
        </div>
      </div>
    </Link>
  );
};

export default PlaceItem;
PlaceItem.propTypes = {
  item: shape({}),
  col: string,
};
