import React, { useState } from "react";

import { Link } from "react-router-dom";
import Button from "../Button";

import { oneOfType, arrayOf, node, string } from "prop-types";

export const Tab = ({ children }) => (
  <div className={"single-tab"}>{children}</div>
);

export const Tabs = ({ children, link }) => {
  const [activeTab, setActiveTab] = useState(0);
  const tabsData = children && children?.map((item) => item?.props);

  return (
    <div className="tabs-component">
      <div className="tabs-header">
        {tabsData?.length > 0 &&
          tabsData?.map((item, index) => (
            <React.Fragment key={index}>
              <React.Fragment>
                {item?.title && (
                  <Button
                    className={`transparent${
                      activeTab === index ? " active" : ""
                    }`}
                    onClick={() => setActiveTab(index)}>
                    {item.title}
                  </Button>
                )}
              </React.Fragment>
            </React.Fragment>
          ))}

        {link && (
          <Link className="link-more" to={link}>
            Więcej
          </Link>
        )}
      </div>
      <div className="tabs-content">{children[activeTab]}</div>
    </div>
  );
};

Tab.propTypes = {
  children: oneOfType([arrayOf(node), node]),
};

Tabs.propTypes = {
  children: oneOfType([arrayOf(node), node]),
  link: string,
};
