import React, { useContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";

import {
  Redirect,
  Switch,
  Route,
  useHistory,
  useLocation,
} from "react-router-dom";

import Header from "../Header";
import SchedulerHeader from "../SchedulerHeader";
import Footer from "../Footer";

import HomePage from "../../pages/HomePage";
import ArticlesPage from "../../pages/ArticlesPage/ArticlesPage";
import ProductsPage from "../../pages/ProductsPage";
import InstitutionPage from "../../pages/InstitutionPage";
import Employees from "../../pages/VetPanelPage/employees";
import VetPanelVisits from "../../pages/VetPanelVisits";
import VetPanelPlanned from "../../pages/VetPanelPlanned";
import ProductPage from "../../pages/ProductPage/";
import ArticlePage from "../../pages/ArticlePage/";

import MyData from "../../pages/UserPanelPage/MyData";
import Settings from "../../pages/UserPanelPage/Settings";
import PetsList from "../../pages/UserPanelPage/PetsList/index";
import Visits from "../../pages/UserPanelPage/Visits";

import routes from "../../routes";
import LoginModal from "../LoginModal";
import RegisterModal from "../RegisterModal/RegisterModal";
import RemindPassword from "../RemindPassword";
import SetNewPasswordModal from "../Modals/SetNewPasswordModal";
import RequestStatusModal from "../Modals/RequestStatusModal";

import {
  LoginModalContext,
  RegisterModalContext,
  RemindPasswordContext,
} from "../../contexts/modals";

import { getUserProfile, emailSendError } from "../../actions/UserActions";

import moment from "moment";
import "moment/locale/pl";

import { typeDoctor, typeReceptionist } from "../../values/UserTypes";

const Main = () => {
  const {
    facility,
    articles,
    products,
    profile,
    vet,
    product,
    article,
    providerAuth,
    home,
    confirmEmail,
    resetPassword,
  } = routes;

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const history = useHistory();

  const { showLoginModal } = useContext(LoginModalContext);
  const { showRegisterModal } = useContext(RegisterModalContext);
  const { showRemindPasswordModal } = useContext(RemindPasswordContext);

  const token = auth?.token || localStorage?.getItem("token");
  const isLogged = !!auth?.user?.id;
  const isDoctor =
    isLogged &&
    (auth?.user?.role === typeDoctor || auth?.user?.role === typeReceptionist);
  const isReceptionist = isLogged && auth?.user?.role === typeReceptionist;

  useEffect(() => {
    auth?.token && localStorage.setItem("token", auth.token);
  }, [auth]);

  useEffect(() => {
    token && dispatch(getUserProfile());
  }, [token, dispatch]);

  useEffect(() => {
    if (isDoctor && history?.location?.pathname !== `${vet}/wizyty`) {
      history.push(`${vet}/wizyty`);
    }
  }, [isDoctor, isReceptionist, history, vet]);

  const location = useLocation();
  const qData = useMemo(() => queryString?.parse(location?.search), [location]);

  useEffect(() => {
    moment.locale("pl");
  }, []);

  return (
    <>
      {isDoctor || isReceptionist ? (
        <SchedulerHeader
          user={auth.user}
          isDoctor={isDoctor}
          isReceptionist={isReceptionist}
        />
      ) : (
        <Header loggedIn={isLogged} />
      )}

      <main className="main">
        <Switch>
          <Route exact path={home} component={HomePage}>
            {(isDoctor || isReceptionist) && (
              <Redirect to={`${vet}/wizyty`} component={VetPanelVisits} />
            )}
          </Route>

          <Route path={facility} component={InstitutionPage} />
          <Route path={articles} component={ArticlesPage} />
          <Route path={products} component={ProductsPage} />
          <Route path={product} component={ProductPage} />
          <Route path={article} component={ArticlePage} />
          <Route path={providerAuth} component={HomePage} />
          <Route path={confirmEmail} component={HomePage} />
        </Switch>

        {isLogged && (
          <Switch>
            <Route exact path={`${profile}`} component={Visits} />
            <Route path={`${profile}/moi-pupile`} component={PetsList} />
            <Route path={`${profile}/moje-dane`} component={MyData} />
            <Route path={`${profile}/ustawienia`} component={Settings} />
          </Switch>
        )}

        {(isDoctor || isReceptionist) && (
          <Switch>
            <Route path={`${vet}/wizyty`} component={VetPanelVisits} />
            <Route path={`${vet}/planowanie`} component={VetPanelPlanned} />
          </Switch>
        )}

        {isReceptionist && (
          <Switch>
            <Route path={`${vet}/employees`} component={Employees} />
          </Switch>
        )}

        {Object.keys(qData).length > 0 && (
          <Switch>
            <Route
              path={resetPassword}
              component={() => <SetNewPasswordModal qData={qData} />}
            />
          </Switch>
        )}

        {showLoginModal && <LoginModal />}
        {showRegisterModal && <RegisterModal />}
        {showRemindPasswordModal && <RemindPassword />}
        {auth?.showSendMailModal && (
          <RequestStatusModal
            close={() => dispatch(emailSendError(false))}
            successComponent={<>Mail resetujący hasło został wysłany</>}
          />
        )}
      </main>
      <Footer loggedIn={isLogged} isDoctor={isDoctor} />
    </>
  );
};

export default Main;
