import React, { useState } from "react";
import PropTypes from "prop-types";

import Modal from "react-modal";
import Icon from "../Icon";
import Button from "../Button";
import InputComponent from "../Input";
import NumberFormat from "react-number-format";

import { useDispatch } from "react-redux";

import { updateUser } from "../../actions/UserActions";

import { validateEmail } from "../../utils/utils";

const EditMyDataModal = ({ close, userData }) => {
  const dispatch = useDispatch();

  const [user, setUser] = useState(userData);
  const [error, setError] = useState({});

  const updateFormData = (key, val) => {
    setUser({ ...user, [key]: val });
    if (val?.length === 0) {
      setError({ ...error, [key]: true });
    } else {
      setError({ ...error, [key]: false });
    }

    if (key === "phone" && val.length > 0 && val.length < 9) {
      setError({ ...error, [key]: true });
    } else {
      setError({ ...error, [key]: false });
    }
  };

  const addPetFn = () => {
    let formDataItem = {};
    Object.keys(user).forEach((item, i) => {
      if (user[item]?.length === 0) {
        formDataItem[item] = true;
      } else {
        formDataItem[item] = false;
      }
    });

    if (user.phone.length > 0 && user.phone.length < 9) {
      setError((formDataItem["phone"] = true));
    } else {
      setError((formDataItem["phone"] = false));
    }

    if (!validateEmail(user.email)) {
      setError((formDataItem["email"] = true));
    }

    setError(formDataItem);

    if (
      formDataItem.email === false &&
      formDataItem.firstName === false &&
      formDataItem.lastName === false &&
      formDataItem.phone === false
    ) {
      dispatch(updateUser(userData.id, user));
      close();
    }
  };

  return (
    <Modal
      isOpen={true}
      className="edit-my-data-modal modal"
      overlayClassName="modal-overlay"
      ariaHideApp={false}
      onRequestClose={close}
    >
      <div className="modal-head">
        <h2>Edycja moich danych</h2>

        <Button onClick={close} className="transparent padding-0">
          <Icon name="close" color="white" />
        </Button>
      </div>

      <div className="modal-content">
        <fieldset>
          <InputComponent
            placeholder="Imię"
            name="firstName"
            value={user.firstName}
            onChange={(e) => updateFormData("firstName", e.target.value)}
          />
          {error.firstName && <span className="error">Proszę wpisz imię.</span>}
        </fieldset>
        <fieldset>
          <InputComponent
            placeholder="Nazwisko"
            name="lastName"
            value={user.lastName}
            onChange={(e) => updateFormData("lastName", e.target.value)}
          />
          {error.lastName && (
            <span className="error">Proszę wpisz nazwisko.</span>
          )}
        </fieldset>
        <fieldset>
          <InputComponent
            placeholder="E-mail"
            name="email"
            value={user.email}
            onChange={(e) => updateFormData("email", e.target.value)}
          />
          {error.email && (
            <span className="error">Proszę wpisz poprawny e-mail.</span>
          )}
        </fieldset>
        <fieldset>
          <NumberFormat
            placeholder="Numer telefonu"
            format="### ### ###"
            mask="_"
            value={user.phone}
            className="input-component"
            onValueChange={(number) => updateFormData("phone", number.value)}
          />
          {error.phone && (
            <span className="error">Numer telefonu powinien mieć 9 cyfr.</span>
          )}
        </fieldset>
        <Button onClick={addPetFn}>Zaktualizuj dane</Button>
      </div>
    </Modal>
  );
};

export default EditMyDataModal;

EditMyDataModal.propTypes = {
  close: PropTypes.func.isRequired,
};
