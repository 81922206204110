import React, { useState, useMemo, useEffect, useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";

import { addDoctor, editDoctor } from "../../actions/DoctorsActions";

import Button from "../../components/Button";
import Modal from "react-modal";
import InputComponent from "../../components/Input";
import Select from "../../components/FormComponents/Select";

import { shape, bool, string, number, func } from "prop-types";

const EmployeeModal = ({ hideModal, data, loading }) => {
  const dispatch = useDispatch();

  const specializations = useSelector((state) => state.specializations);
  const places = useSelector((state) => state.places);

  const defaultData = {
    title: "",
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    specializations: "",
    place: "",
  };

  const [formData, setFormData] = useState(defaultData);

  const updaeFormData = (key, val) => {
    setFormData({ ...formData, [key]: val });
  };

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      data?.id
        ? dispatch(editDoctor(data.id, formData))
        : dispatch(addDoctor(formData));
    },
    [formData, data, dispatch]
  );

  const specializationsOptions = useMemo(() => {
    if (specializations?.list?.length > 0) {
      return specializations.list.map((specialization) => {
        return {
          value: specialization.id,
          name: specialization.type,
        };
      });
    }
    return [];
  }, [specializations]);

  const placesOptions = useMemo(() => {
    if (places?.placesList?.length > 0) {
      return places.placesList.map((place) => {
        return {
          value: place.id,
          name: place.name,
        };
      });
    }
    return [];
  }, [places]);

  useEffect(() => {
    if (data?.id) {
      setFormData({
        ...data,
        title: data.title,
        firstName: data.user.firstName,
        lastName: data.user.lastName,
        phone: data.user.phone,
        email: data.user.email,
        specializations: "",
        place: data.place.id,
      });
    }
  }, [data]);

  return (
    <Modal
      isOpen={true}
      className="add-new-employee modal"
      overlayClassName="modal-overlay modal-overlay--top"
      ariaHideApp={false}
      onRequestClose={hideModal}
    >
      <form onSubmit={!loading && onSubmit}>
        <div className="modal-header">
          <h2>
            {data?.id
              ? "Zaktualizuj dane pracownika"
              : "Dodaj nowego pracownika"}
          </h2>
          <div>
            <Button className="transparent" onClick={hideModal}>
              Anuluj
            </Button>
            <Button loading={loading} className="transparent" htmlType="submit">
              {data?.id ? "Zapisz" : "Dodaj"}
            </Button>
          </div>
        </div>

        <div className="modal-content">
          <div className="row">
            <div className="col-4">
              <InputComponent
                placeholder="Tytuł"
                name="title"
                value={formData.title}
                onChange={(e) => {
                  updaeFormData("title", e.target.value);
                }}
              />
            </div>
            <div className="col-10">
              <InputComponent
                placeholder="Imię"
                minLength={2}
                required
                name="name"
                value={formData.firstName}
                onChange={(e) => {
                  updaeFormData("firstName", e.target.value);
                }}
              />
            </div>
            <div className="col-10">
              <InputComponent
                placeholder="Naziwsko"
                name="lastName"
                required
                minLength={2}
                value={formData.lastName}
                onChange={(e) => {
                  updaeFormData("lastName", e.target.value);
                }}
              />
            </div>
            <div className="col-24">
              <InputComponent
                placeholder="Telefon"
                name="phone"
                minLength={9}
                type="tel"
                required
                value={formData.phone}
                onChange={(e) => {
                  updaeFormData("phone", e.target.value);
                }}
              />
              <InputComponent
                placeholder="Email"
                name="email"
                type="email"
                required
                value={formData.email}
                minLength={4}
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                onChange={(e) => {
                  updaeFormData("email", e.target.value);
                }}
              />
              <Select
                defaultValue={formData.specializations}
                loading={specializations.loading}
                name="specializations"
                placeholder="Specjalizacja"
                options={specializationsOptions}
                onInputChange={(selected) =>
                  updaeFormData("specializations", selected)
                }
              />

              <Select
                defaultValue={formData.place}
                loading={places?.placesLoading}
                name="place"
                placeholder="Placówka"
                options={placesOptions}
                onInputChange={(selected) => updaeFormData("place", selected)}
              />
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default EmployeeModal;

EmployeeModal.propTypes = {
  hideModal: func,
  showStatusModal: func,
  loading: bool,
  data: shape({
    created_at: string.isRequired,
    displayPhone: string,
    hidden: bool,
    id: number.isRequired,
    place: shape({}),
    title: string,
    updated_at: string.isRequired,
    user: shape({}),
    workingHours: shape({}),
  }).isRequired,
};
