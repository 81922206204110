import { errorParser } from "../api/ErrorParser";
import * as types from "./PetsConstants";
import { petsApi } from "../api/PetsApi";

export const fetchPets = () => ({
  type: types.FETCH_PETS,
});

export const savePets = (data) => ({
  type: types.FETCH_PETS_SUCCESS,
  payload: data,
});

export const errorPets = (error) => ({
  type: types.FETCH_PETS_FAILURE,
  payload: error,
});

export const getPets = (userId) => (dispatch) => {
  dispatch(fetchPets());
  return petsApi
    .getAllUserPets(userId)
    .then((res) => {
      dispatch(savePets(res.data));
      return res.data;
    })
    .catch((err) => {
      dispatch(errorPets(errorParser.parseError(err).message));
    });
};

export const fetchPetsTypes = () => ({
  type: types.FETCH_PETS_TYPES,
});

export const savePetsTypes = (data) => ({
  type: types.FETCH_PETS_TYPES_SUCCESS,
  payload: data,
});

export const errorPetsTypes = (error) => ({
  type: types.FETCH_PETS_TYPES_FAILURE,
  payload: error,
});

export const getPetsTypes = () => (dispatch) => {
  dispatch(fetchPetsTypes());
  petsApi
    .getPetsTypes()
    .then((res) => {
      dispatch(savePetsTypes(res.data));
    })
    .catch((err) => {
      dispatch(errorPetsTypes(errorParser.parseError(err).message));
    });
};

export const addPetStart = () => ({
  type: types.ADD_PET,
});

export const addPetError = (error) => ({
  type: types.ADD_PET_FAILURE,
  payload: error,
});

export const saveStatus = (data) => ({
  type: types.FETCH_STATUS_SUCCESS,
  payload: data,
});

export const addPet = (pet, avatar) => (dispatch) => {
  dispatch(addPetStart());
  return petsApi
    .addPet(pet, avatar)
    .then((res) => {
      dispatch(saveStatus(res.status));
      dispatch(getPets());
    })
    .catch((err) => {
      dispatch(addPetError(errorParser.parseError(err).message));
    });
};

export const updatePetStart = () => ({
  type: types.UPDATE_PET,
});

export const updatePetError = (error) => ({
  type: types.UPDATE_PET_FAILURE,
  payload: error,
});

export const updatePet = (petId, pet, avatar) => (dispatch) => {
  dispatch(updatePetStart());
  return petsApi
    .updatePet(petId, pet, avatar)
    .then((res) => {
      dispatch(getPets(pet.user.id));
    })
    .catch((err) => {
      dispatch(addPetError(errorParser.parseError(err).message));
    });
};

export const deletePet = (petId, userId) => (dispatch) => {
  dispatch(updatePetStart());
  return petsApi
    .deletePet(petId)
    .then(() => {
      dispatch(getPets(userId));
    })
    .catch((err) => {
      dispatch(addPetError(errorParser.parseError(err).message));
    });
};

export const getPetHistoryStart = () => ({
  type: types.FETCH_PET_HISTORY,
});

export const getPetHistoryError = (error) => ({
  type: types.FETCH_PET_HISTORY_FAILURE,
  payload: error,
});

export const getPetHistorySuccess = (data) => ({
  type: types.FETCH_PET_HISTORY_SUCCESS,
  payload: data,
});

export const getPetHistory = (id) => (dispatch) => {
  dispatch(getPetHistoryStart());
  return petsApi
    .getPetHistory(id)
    .then((res) => {
      dispatch(getPetHistorySuccess(res.data.link));
    })
    .catch((err) => {
      dispatch(getPetHistoryError(errorParser.parseError(err).message));
    });
};
