import React from "react";

import { Link } from "react-router-dom";

import { getUrlToImage, formatLongText } from "../../utils/utils";

import { shape, string } from "prop-types";

const ArticleItem = ({ article, size }) => {
  const img = article.cover?.length > 0 ? article.cover[0]?.url : null;

  return (
    <div className={size === "small" ? "col-24" : "col-12"}>
      <Link to={`/article/${article.id}`} className={`article-item ${size}`}>
        {img && <img className="article-img" src={getUrlToImage(img)} alt="" />}
        <div className="content">
          <span>{article.type}</span>
          <h3>{formatLongText(article.title, 50)}</h3>
        </div>
      </Link>
    </div>
  );
};

export default ArticleItem;
ArticleItem.propTypes = {
  article: shape({}),
  size: string,
};
