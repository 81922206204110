import React, { useState } from "react";
import { arrayOf, shape, number, string, func, oneOfType } from "prop-types";
import Loader from "react-loader-spinner";

const Select = ({
  name,
  options,
  onInputChange,
  defaultValue = "",
  loading = false,
  placeholder = "Wybierz",
}) => {
  const [selected, setSelected] = useState(defaultValue || "");

  const onChangeValue = (value) => {
    onInputChange(value);
    setSelected(value);
  };

  return (
    <div className="custom-select">
      {loading && (
        <Loader
          className="loader"
          type="Oval"
          color="#f66829"
          height={20}
          width={20}
        />
      )}
      <select
        onChange={(e) => onChangeValue(e.target.value)}
        disabled={loading}
        value={selected}
        name={name}
      >
        <option value="" disabled>
          {placeholder}
        </option>
        {options &&
          !loading &&
          options?.map((option, index) => {
            return (
              <option value={option.value} key={index}>
                {option.name}
              </option>
            );
          })}
      </select>
    </div>
  );
};

export default Select;

Select.propTypes = {
  name: string.isRequired,
  placeholder: string,
  options: arrayOf(
    shape({
      value: number | string,
      name: string,
    })
  ).isRequired,
  onInputChange: func.isRequired,
  defaultValue: oneOfType([string, number]),
};
