import * as actions from "../actions/ArticlesConstants";

const initialState = {
  data: {},
  loading: false,
  errorMessage: "",
};

const articleReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_ARTICLE:
      return {
        ...state,
        loading: true,
      };
    case actions.FETCH_ARTICLE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case actions.FETCH_ARTICLE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default articleReducer;
