import React, {
  useMemo,
  useRef,
  useEffect,
  useState,
  useContext,
  useCallback,
} from "react";
import { useHistory, useLocation } from "react-router-dom";

import Button from "../Button";
import Icon from "../Icon";
import BookVisit from "../BookVisit";

import { getUrlToImage, getMapLnk } from "../../utils/utils";

import { LoginModalContext } from "../../contexts/modals";

import queryString from "query-string";

import { shape } from "prop-types";

import moment from "moment";

const Clinic = ({ data }) => {
  const [showForm, setShowForm] = useState(false);

  const history = useHistory();
  const hLocation = useLocation();

  let openHours = data?.openHours;
  openHours = Object.keys(openHours).map((key) => ({
    [key]: openHours[key] ? openHours[key].toString().slice(0, 5) : "-",
  }));

  data.openHours = Object.assign({}, ...openHours);

  const {
    fridayFrom,
    fridayTo,
    mondayFrom,
    mondayTo,
    saturdayFrom,
    saturdayTo,
    sundayFrom,
    sundayTo,
    thursdayFrom,
    thursdayTo,
    tuesdayFrom,
    tuesdayTo,
    wednesdayFrom,
    wednesdayTo,
  } = data.openHours;

  const { showLoginModal } = useContext(LoginModalContext);

  const { id, name, logo, address, desc, location, spec } = data;

  const clinicRef = useRef(null);

  const qData = useMemo(() => {
    return queryString.parse(hLocation?.search);
  }, [hLocation]);

  const selectedPlace = useMemo(() => {
    return qData.place;
  }, [qData]);

  const visitId = useMemo(() => {
    return qData.visit;
  }, [qData]);

  const active = useMemo(() => {
    return selectedPlace && id === Number(selectedPlace);
  }, [selectedPlace, id]);

  const imgSrc = logo?.url && getUrlToImage(logo.url);

  const onExitForm = useCallback(() => {
    setShowForm(false);
    if (visitId && history?.location?.pathname !== "/panel-uzytkownika") {
      history.push("/panel-uzytkownika");
    }
  }, [visitId, history]);

  useEffect(() => {
    if (clinicRef?.current && active) {
      clinicRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [clinicRef, active]);

  useEffect(() => {
    if (visitId) {
      setShowForm(true);
    }
  }, [visitId]);

  return (
    <div className={`clinic ${active ? " active" : ""}`}>
      <div
        ref={clinicRef}
        onClick={() => history.push(`?place=${id}`)}
        className="head"
      >
        <div>
          <h3>{name}</h3>
          <h5>
            Specjalizacj{spec?.length > 1 ? "e" : "a"}:{" "}
            {spec.length > 0
              ? spec.map((item, i) => (
                  <span key={i}>
                    {item.type}
                    {i !== spec.length - 1 && ", "}
                  </span>
                ))
              : "Brak danych"}
          </h5>
          <address>
            ul. {address?.street || "Brak danych"} <br />
            {address?.zip || "Brak danych"} {address?.city || "Brak danych"}
          </address>

          <a
            className="link-with-icon"
            rel="noopener noreferrer"
            href={getMapLnk(location)}
            target="_blank"
          >
            <span>Nawiguj</span> <Icon name="navigate" />
          </a>
        </div>

        {imgSrc && (
          <img src={imgSrc} alt={logo?.alternativeText || "logo kliniki"} />
        )}
      </div>

      {active && (
        <>
          <div className="description">{desc}</div>

          <table className="open-hours-table">
            <thead>
              <tr>
                {moment.weekdaysShort(true).map((day, index) => (
                  <th key={index}>{day}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div>
                    <span>{mondayFrom}</span>
                    <Icon name="arrow_down" color="orange" />
                    <span>{mondayTo}</span>
                  </div>
                </td>

                <td>
                  <div>
                    <span>{tuesdayFrom}</span>
                    <Icon name="arrow_down" color="orange" />
                    <span>{tuesdayTo}</span>
                  </div>
                </td>

                <td>
                  <div>
                    <span>{wednesdayFrom}</span>
                    <Icon name="arrow_down" color="orange" />
                    <span>{wednesdayTo}</span>
                  </div>
                </td>

                <td>
                  <div>
                    <span>{thursdayFrom}</span>
                    <Icon name="arrow_down" color="orange" />
                    <span>{thursdayTo}</span>
                  </div>
                </td>

                <td>
                  <div>
                    <span>{fridayFrom}</span>
                    <Icon name="arrow_down" color="orange" />
                    <span>{fridayTo}</span>
                  </div>
                </td>

                <td>
                  <div>
                    <span>{saturdayFrom}</span>
                    <Icon name="arrow_down" color="orange" />
                    <span>{saturdayTo}</span>
                  </div>
                </td>

                <td>
                  <div>
                    <span>{sundayFrom}</span>
                    <Icon name="arrow_down" color="orange" />
                    <span>{sundayTo}</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <Button onClick={() => setShowForm(true)} className="transparent">
            Szczegóły i rezerwacje
          </Button>
          {showForm && !showLoginModal && (
            <BookVisit data={data} onHideForm={() => onExitForm()} />
          )}
        </>
      )}
    </div>
  );
};

export default Clinic;
Clinic.propTypes = {
  data: shape({}),
};
