import * as actions from "../actions/ProductsConstants";

const initialState = {
  typesList: [],
  productsList: [],
  featuredProducts: [],
  productsLoading: false,
  typesLoading: false,
  errorMessage: "",
  byId: {},
};

const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_TYPES_PRODUCTS:
      return {
        ...state,
        typesLoading: true,
      };
    case actions.FETCH_FEATURED_PRODUCTS:
    case actions.FETCH_PRODUCTS:
      return {
        ...state,
        productsLoading: true,
      };
    case actions.FETCH_PRODUCT:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.id]: state.byId[action.id]
            ? { ...state.byId[action.id], loading: true, errorMessage: "" }
            : { loading: true, errorMessage: "" },
        },
      };

    case actions.FETCH_PRODUCT_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...state.byId[action.payload.id],
            ...action.payload,
            loading: false,
            errorMessage: "",
          },
        },
      };
    case actions.FETCH_PRODUCT_FAILURE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action?.id]: {
            ...state.byId[action?.id],
            loading: false,
            errorMessage: action.payload,
          },
        },
      };

    case actions.FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        productsLoading: false,
        productsList: action.payload,
      };

    case actions.FETCH_FEATURED_PRODUCTS_SUCCESS:
      return {
        ...state,
        productsLoading: false,
        featuredProducts: action.payload,
      };

    case actions.FETCH_TYPES_PRODUCTS_SUCCESS:
      return {
        ...state,
        typesLoading: false,
        typesList: action.payload,
      };
    case actions.FETCH_TYPES_PRODUCTS_FAILURE:
      return {
        ...state,
        typesLoading: false,
        errorMessage: action.payload,
      };
    case actions.FETCH_FEATURED_PRODUCTS_FAILURE:
    case actions.FETCH_PRODUCTS_FAILURE:
      return {
        ...state,
        productsLoading: false,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default productsReducer;
