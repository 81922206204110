import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import Button from "../Button";
import Icon from "../Icon";
import { LoginModalContext, RegisterModalContext } from "../../contexts/modals";
import { bool } from "prop-types";

const Footer = ({ loggedIn, isDoctor }) => {
  const { setShowLoginModal } = useContext(LoginModalContext);
  const { setShowRegisterModal } = useContext(RegisterModalContext);

  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <NavLink to="/">
            <Icon name="logo_2" color="footer-logo" />
          </NavLink>

          {!isDoctor && (
            <>
              <NavLink to="/placowki">Szukaj placówki</NavLink>
              <NavLink to="/artykuly">Dobrze wiedzieć</NavLink>
              <NavLink to="/produkty">Produkty</NavLink>

              {loggedIn ? (
                <NavLink to="/panel-uzytkownika/wizyty">
                  <Icon name="user_icon" color="orange" />
                  Panel Użytkownika
                </NavLink>
              ) : (
                <>
                  <Button
                    className="transparent"
                    onClick={() => setShowRegisterModal(true)}
                  >
                    Rejestracja
                  </Button>
                  <Button
                    className="transparent"
                    onClick={() => setShowLoginModal(true)}
                  >
                    Logowanie
                  </Button>
                </>
              )}
            </>
          )}

          <Button
            className="back-to-top-btn"
            onClick={() => window.scroll({ top: 0, behavior: "smooth" })}
          >
            <Icon name="arrow_up" color="arrow_up" />
          </Button>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
Footer.propTypes = {
  loggedIn: bool,
  isDoctor: bool,
};
