import React, {
  useState,
  useMemo,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { LoginModalContext } from "../../contexts/modals";

import { getAvatar, getUrlToImage } from "../../utils/utils";
import { getPets } from "../../actions/PetsActions";
import {
  reserveVisit,
  postponeVisit,
  getVisit,
} from "../../actions/VisitsActions";
import { getDoctors, getDoctorTimeslots } from "../../actions/DoctorsActions";

import Input from "../Input";
import Calendar from "../Calendar";
import Button from "../Button";
import RadioInput from "../FormComponents/Radio";
import Loader from "react-loader-spinner";

import queryString from "query-string";
import { strings } from "../../values/Strings";

import moment from "moment";

const BookVisitForm = () => {
  const dispatch = useDispatch();

  const location = useLocation();

  const [state, setState] = useState({
    date: new Date(),
    doctor: "",
    time: "",
    pet: "",
    comment: "",
    desc: "",
  });

  const qData = useMemo(() => {
    return queryString.parse(location?.search);
  }, [location]);

  const { visit: visitId, place: placeId } = qData;

  const visits = useSelector((state) => state.visits);
  const visit = visits?.byId[visitId];
  const petsList = useSelector((state) => state.pets?.data);
  const user = useSelector((state) => state.auth.user);
  const doctors = useSelector((state) => state.doctors.doctors);
  const timeslots = useSelector((state) => state.doctors.timeslots);

  const { setShowLoginModal } = useContext(LoginModalContext);

  const isLogged = useMemo(() => {
    return !!user?.id;
  }, [user]);

  const loading = visit?.loading || visits?.loading;

  const handleReservation = useCallback(() => {
    const fullDate = `${moment(state.date).format(strings.full_date_2)} ${
      state.time
    }`;

    const start = moment(fullDate).toISOString();
    const end = moment(fullDate).add(15, "minutes").toISOString();

    const data = {
      ...state,
      start,
      end,
    };

    if (visit?.data?.id) {
      dispatch(postponeVisit(visit.data.id, data));
    } else {
      dispatch(reserveVisit(data));
    }
  }, [state, visit, dispatch]);

  const allTimeSlots = useMemo(() => {
    return timeslots?.data?.map((t) => {
      const val = moment(t).format(strings.hours);
      return { name: val, value: val };
    });
  }, [timeslots]);

  useEffect(() => {
    if (visitId) {
      dispatch(getVisit(visitId));
    }
  }, [visitId, dispatch]);

  useEffect(() => {
    if (doctors?.data?.length > 0 && !visit?.data?.doctor) {
      setState((prevState) => ({
        ...prevState,
        doctor: doctors?.data[0].id,
      }));
    }
  }, [doctors, visit]);

  useEffect(() => {
    if (petsList?.length > 0 && !visit?.data?.pet) {
      setState((prevState) => ({
        ...prevState,
        pet: petsList[0].id,
      }));
    }
  }, [petsList, visit]);

  useEffect(() => {
    if (allTimeSlots?.length > 0) {
      setState((prevState) => ({
        ...prevState,
        time: allTimeSlots[0].value,
      }));
    }
  }, [allTimeSlots]);

  useEffect(() => {
    isLogged && dispatch(getPets(user.id));
  }, [isLogged, dispatch, user]);

  useEffect(() => {
    dispatch(getDoctors(placeId));
  }, [dispatch, placeId]);

  useEffect(() => {
    if (state.doctor && state.date) {
      dispatch(getDoctorTimeslots(state.doctor, state.date));
    }
  }, [dispatch, state.doctor, state.date]);

  useEffect(() => {
    if (visit?.data?.id) {
      setState({
        ...visit.data,
        date: moment(visit?.data?.start),
        doctor: visit?.data?.doctor?.id,
        time: moment(visit?.data?.start).format("HH:mm"),
      });
    }
  }, [visit]);

  return (
    <>
      {visit?.loading && (
        <div className="loader-wrapper">
          <Loader type="Oval" color="#f66829" height={100} width={100} />
        </div>
      )}

      <h2>{visit?.data?.id ? "Przełóż wizytę" : "Rezerwuj wizytę"}</h2>
      <section className="book-visit__right__section--calendar">
        <Calendar
          selectedDate={new Date(state.date)}
          onDateSelect={(date) => setState({ ...state, date })}
        />
      </section>

      <section>
        <h3>Wybierz weterynarza</h3>
        <div>
          {doctors?.data?.length > 0 ? (
            <RadioInput
              name="doctor"
              withImages
              defaultValue={state.doctor}
              onInputChange={(option) => setState({ ...state, doctor: option })}
              options={doctors?.data?.map((doctor) => {
                return {
                  value: doctor.id,
                  name: `${doctor?.user?.firstName || "Brak"} ${
                    doctor?.user?.lastName || ""
                  }`,
                  img: doctor?.image?.url ? getUrlToImage(doctor) : null,
                };
              })}
            />
          ) : (
            <span className="no-data">Brak dostępnych pracowników.</span>
          )}
        </div>
      </section>

      <section>
        <h3>Dostępne godziny wizyt</h3>

        {timeslots?.loading ? (
          <Loader type="Oval" color="#f66829" height={40} width={40} />
        ) : !state.date !== null && allTimeSlots?.length > 0 ? (
          <RadioInput
            name="time"
            className="small"
            defaultValue={state.time}
            onInputChange={(option) => setState({ ...state, time: option })}
            options={allTimeSlots}
          />
        ) : (
          <span className="no-data">
            Brak dostępnych godzin, wybierz inną datę lub weterynarza.
          </span>
        )}
      </section>
      {isLogged && (
        <section>
          <h3>Który pupil będzie pacjentem</h3>
          {petsList?.length > 0 ? (
            <RadioInput
              name="pet"
              withImages
              defaultValue={state.pet}
              onInputChange={(option) => setState({ ...state, pet: option })}
              iconPlaceholder="pet"
              options={petsList?.map((pet) => {
                return {
                  value: pet.id,
                  name: pet.name,
                  img: getAvatar(pet),
                };
              })}
            />
          ) : (
            <span className="no-data">
              Nie znaleźliśmy żadnego Twojego pupila. Dodaj go w{" "}
              <Link to="/profile/moi-pupile">panelu użytkownika</Link> aby móc
              umówić go na wizytę.
            </span>
          )}
        </section>
      )}
      {isLogged && (
        <>
          <section>
            <h3>Notatka dla lekarza</h3>
            <textarea
              value={state.comment}
              rows="4"
              onChange={(e) => setState({ ...state, comment: e.target.value })}
              placeholder="(Opcjonalnie napisz kilka słów)"
            ></textarea>
          </section>
          <section>
            <h3>Czego dotyczy wizyta</h3>
            <Input
              value={state.desc}
              onChange={(e) => setState({ ...state, desc: e.target.value })}
              placeholder="(Opcjonalnie napisz kilka słów)"
            />
          </section>
        </>
      )}
      <section className="book-visit__right__section__button-wrapper">
        {!isLogged && (
          <p>Zaloguj się aby móc zarezerwować wizytę dla swojego pupila.</p>
        )}
        {isLogged ? (
          <Button loading={loading} onClick={() => handleReservation()}>
            {visit?.data?.id ? "Zatwierdź" : "Rezerwuj"}
          </Button>
        ) : (
          <Button onClick={() => setShowLoginModal(true)}>Zaloguj</Button>
        )}
      </section>
    </>
  );
};

export default BookVisitForm;
