import React, { useEffect, useState, useContext } from "react";

import { useHistory, useLocation, useParams } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import routes from "../../routes";

import Banner from "../../components/Banner";
import ProductsSection from "../../components/ProductsSection/ProductsSection";
import ArticlesSection from "../../components/ArticlesSection/ArticlesSection";
import RegisterSection from "../../components/RegisterSection";
import PlacesSection from "../../components/PlacesSection";
import RequestStatusModal from "../../components/Modals/RequestStatusModal";

import { LoginModalContext } from "../../contexts/modals";

import { loginWithProvider } from "../../actions/UserActions";

const HomePage = () => {
  const [showModal, setShowModal] = useState(false);

  const { confirmEmail } = routes;

  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);

  const location = useLocation();
  const params = useParams();
  const history = useHistory();

  const { setShowLoginModal, showLoginModal } = useContext(LoginModalContext);

  useEffect(() => {
    if (params.providerName) {
      dispatch(loginWithProvider(params.providerName, location.search));
    }
  }, [history, location.search, params.providerName, dispatch]);

  useEffect(() => {
    if (
      location.pathname === confirmEmail &&
      !auth?.user?.id &&
      !showLoginModal
    ) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [auth, location, confirmEmail, showLoginModal]);

  return (
    <div className="container">
      <Banner />
      <PlacesSection />
      <ProductsSection />
      <ArticlesSection />
      {!auth?.user?.id && <RegisterSection />}

      {showModal && (
        <RequestStatusModal
          showModal={true}
          close={() => setShowModal(false)}
          successComponent={
            <>
              <h2>Potwierdzono rejetrację!</h2>
              <p>
                <button
                  className="button link"
                  onClick={() => {
                    setShowModal(false);
                    setShowLoginModal(true);
                  }}
                >
                  Zaloguj się
                </button>{" "}
                na swoje konto.
              </p>
            </>
          }
        />
      )}
    </div>
  );
};

export default HomePage;
