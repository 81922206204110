import * as actions from "../actions/PetsConstants";

const initialState = {
  data: [],
  types: [],
  loading: false,
  errorMessage: "",
  success: null,
  petHistoryLink: "",
};

const petsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.UPDATE_PET:
    case actions.ADD_PET:
    case actions.FETCH_PETS_TYPES:
    case actions.FETCH_PETS:
      return {
        ...state,
        loading: true,
      };

    case actions.FETCH_PETS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case actions.ADD_PET_FAILURE:
    case actions.FETCH_PETS_TYPES_FAILURE:
    case actions.FETCH_PETS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };

    case actions.FETCH_PETS_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        types: action.payload,
      };

    case actions.FETCH_STATUS_SUCCESS:
      return {
        ...state,
        success: action.payload <= 299 && action.payload >= 200 ? true : false,
      };

    case actions.FETCH_PET_HISTORY:
      return {
        ...state,
        loading: true,
        petHistoryLink: "",
      };

    case actions.FETCH_PET_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        petHistoryLink: action.payload,
      };
    case actions.FETCH_PET_HISTORY_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
};

export default petsReducer;
