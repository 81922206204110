import React, { useState } from "react";

import {
  LoginModalContext,
  RegisterModalContext,
  RemindPasswordContext,
  NewPasswordContext,
} from "./modals";

import { oneOfType, arrayOf, node } from "prop-types";

const ContextsWrapper = ({ children }) => {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showRemindPasswordModal, setShowRemindPasswordModal] = useState(false);
  const [showNewPasswordModal, setShowNewPasswordModal] = useState(false);

  const loginModal = { showLoginModal, setShowLoginModal };
  const registerModal = { showRegisterModal, setShowRegisterModal };
  const remindPassword = {
    showRemindPasswordModal,
    setShowRemindPasswordModal,
  };
  const newPasswordModal = {
    showNewPasswordModal,
    setShowNewPasswordModal,
  };

  return (
    <LoginModalContext.Provider value={loginModal}>
      <RegisterModalContext.Provider value={registerModal}>
        <RemindPasswordContext.Provider value={remindPassword}>
          <NewPasswordContext.Provider value={newPasswordModal}>
            {children}
          </NewPasswordContext.Provider>
        </RemindPasswordContext.Provider>
      </RegisterModalContext.Provider>
    </LoginModalContext.Provider>
  );
};

export default ContextsWrapper;

ContextsWrapper.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
};
