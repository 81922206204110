import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import * as actions from "../../actions/PlacesActions";

import SearchWidget from "../../components/SearchWidget";
import Clinic from "../../components/Clinic";
import InstancesMap from "../../components/Map";
import Loader from "react-loader-spinner";

const InstitutionPage = () => {
  const dispatch = useDispatch();

  const places = useSelector((state) => state.places);

  const { city, type } = useParams();

  useEffect(() => {
    dispatch(
      actions.getPlaces("created_at:DESC", 999, city === "-" ? "" : city, type)
    );
    dispatch(actions.getTypes());
  }, [dispatch, city, type]);

  const loading = places?.placesLoading;

  return (
    <div className="institution-page">
      <div className="container">
        <h1 className="title">Szukaj placówki</h1>
        <div className="content row d-flex align-items-stretch">
          <div className="left col-12">
            <SearchWidget
              defaultCity={city}
              defaultType={type}
              data={places?.typesList || []}
              loading={loading}
            />
            <h3 className="institution-page__places-count">
              Znaleziono <span>{places?.placesList?.length || 0} miejsc</span>
            </h3>
            <div className="clinics-wrapper">
              {loading && (
                <div className="loader-wrapper">
                  <Loader
                    type="Oval"
                    color="#f66829"
                    height={100}
                    width={100}
                  />
                </div>
              )}
              {places?.placesList?.map((place, index) => (
                <Clinic data={place} key={index} />
              ))}
            </div>
          </div>
          <div className="right col-12 align-self-stretch">
            <InstancesMap data={places?.placesList} loading={loading} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstitutionPage;
