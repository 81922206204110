import * as actions from "../actions/ArticlesConstants";

const initialState = {
  list: [],
  loading: false,
  errorMessage: "",
  byId: {},
};

const articlesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_ARTICLES:
      return {
        ...state,
        loading: true,
      };
    case actions.FETCH_ARTICLE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.id]: state.byId[action.id]
            ? { ...state.byId[action.id], loading: true, errorMessage: "" }
            : { loading: true, errorMessage: "" },
        },
      };
    case actions.FETCH_ARTICLE_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...state.byId[action.payload.id],
            ...action.payload,
            loading: false,
            errorMessage: "",
          },
        },
      };
    case actions.FETCH_ARTICLE_FAILURE:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action?.id]: {
            ...state.byId[action?.id],
            loading: false,
            errorMessage: action.payload,
          },
        },
      };

    case actions.FETCH_ARTICLES_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case actions.FETCH_ARTICLES_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default articlesReducer;
