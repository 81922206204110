import React from "react";

import { HashRouter } from "react-router-dom";

import { Provider } from "react-redux";
import store from "./store/ConfigureStore";

import ContextWrapper from "./contexts";

import Main from "./components/Main/Main";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const App = () => {
  return (
    <Provider store={store}>
      <HashRouter>
        <ContextWrapper>
          <Main />
        </ContextWrapper>
      </HashRouter>
    </Provider>
  );
};

export default App;
