import React, { useContext } from "react";
import Button from "../Button";
import { RegisterModalContext } from "../../contexts/modals";

const RegisterSection = () => {
  const { setShowRegisterModal } = useContext(RegisterModalContext);

  return (
    <section className="register-section">
      <h2>Korzystanie z naszego serwisu jest darmowe!</h2>
      <p>Nie posiadasz jeszcze konta?</p>
      <Button onClick={() => setShowRegisterModal(true)}>
        Zarejestruj się
      </Button>
    </section>
  );
};

export default RegisterSection;
