import * as type from "./VisitsConstants";
import { visitsApi } from "../api/VisitsApi";
import { errorParser } from "../api/ErrorParser";

export const fetchVisits = () => ({
  type: type.FETCH_VISITS,
});

export const fetchVisitsSucces = (data) => ({
  type: type.FETCH_VISITS_SUCCESS,
  payload: data,
});

export const fetchVisitsFailure = (data) => ({
  type: type.FETCH_VISITS_FAILURE,
  payload: data,
});

export const getVisits = () => (dispatch) => {
  dispatch(fetchVisits());
  visitsApi
    .getVisits()
    .then((res) => {
      dispatch(fetchVisitsSucces(res.data));
    })
    .catch((err) => {
      dispatch(fetchVisitsFailure(errorParser.parseError(err).message));
    });
};

export const reserveVisitReq = () => ({
  type: type.RESERVE_VISIT,
});

export const reserveVisitReset = () => ({
  type: type.RESERVE_VISIT_RESET,
});

export const reserveVisitSucces = (data) => ({
  type: type.RESERVE_VISIT_SUCCESS,
  payload: data,
});

export const reserveVisitFailure = (data) => ({
  type: type.RESERVE_VISIT_FAILURE,
  payload: data,
});

export const reserveVisit = (data) => (dispatch) => {
  dispatch(reserveVisitReq());
  return visitsApi
    .reserveVisit(data)
    .then((res) => {
      dispatch(reserveVisitSucces(res.data));
    })
    .catch((err) => {
      dispatch(reserveVisitFailure(errorParser.parseError(err).message));
    });
};

export const fetchVisit = () => ({
  type: type.FETCH_VISIT,
});

export const fetchVisitSucces = (data) => ({
  type: type.FETCH_VISIT_SUCCESS,
  payload: data,
});

export const fetchVisitFailure = (data) => ({
  type: type.FETCH_VISIT_FAILURE,
  payload: data,
});

export const getVisit = (id) => (dispatch) => {
  dispatch(fetchVisit());
  return visitsApi
    .getVisit(id)
    .then((res) => {
      dispatch(fetchVisitSucces(res.data));
      return res.data;
    })
    .catch((err) => {
      dispatch(fetchVisitFailure(errorParser.parseError(err).message));
    });
};

export const deleteVisit = () => ({
  type: type.DELETE_VISIT,
});

export const deleteVisitSucces = (data) => ({
  type: type.DELETE_VISIT_SUCCESS,
  payload: data,
});

export const deleteVisitFailure = (data) => ({
  type: type.DELETE_VISIT_FAILURE,
  payload: data,
});

export const cancelVisit = (id) => (dispatch) => {
  dispatch(deleteVisit());
  return visitsApi
    .cancelVisit(id)
    .then((res) => {
      dispatch(deleteVisitSucces(res.data));
      return res.data;
    })
    .catch((err) => {
      dispatch(deleteVisitFailure(errorParser.parseError(err).message));
    });
};

export const postponeVisit = (id, data) => (dispatch) => {
  dispatch(deleteVisit());
  return visitsApi
    .cancelVisit(id)
    .then(() => {
      dispatch(reserveVisit(data));
    })
    .catch((err) => {
      dispatch(deleteVisitFailure(errorParser.parseError(err).message));
    });
};

export const updateVisitSuccess = (data) => ({
  type: type.UPDATE_VISIT_SUCCESS,
  payload: data,
});

export const updateVisitFailure = (error) => ({
  type: type.UPDATE_VISIT_FAILURE,
  payload: error,
});

export const updateVisit = (visit, data) => (dispatch) => {
  return dispatch(cancelVisit(visit)).then((res) => {
    dispatch(reserveVisit(data));
  });
};

export const fetchAllDoctorsVisits = () => ({
  type: type.FETCH_ALL_DOCTOR_VISITS,
});

export const saveAllDoctorsVisits = (data) => ({
  type: type.FETCH_ALL_DOCTOR_VISITS_SUCCESS,
  payload: data,
});

export const errorAllDoctorsVisits = (data) => ({
  type: type.FETCH_ALL_DOCTOR_VISITS_FAILURE,
  payload: data,
});

export const getDoctorVisits = (placeId) => (dispatch) => {
  dispatch(fetchAllDoctorsVisits());
  visitsApi
    .getDoctorVisits(placeId)
    .then((res) => {
      dispatch(saveAllDoctorsVisits(res.data));
    })
    .catch((err) => {
      dispatch(errorAllDoctorsVisits(errorParser.parseError(err).message));
    });
};

export const updateVisitComment = () => ({
  type: type.UPDATE_VISIT_COMMENT,
});

export const updateVisitCommentSucces = (data) => ({
  type: type.UPDATE_VISIT_COMMENT_SUCCESS,
  payload: data,
});

export const updateVisitCommentFailure = (data) => ({
  type: type.UPDATE_VISIT_COMMENT_FAILURE,
  payload: data,
});

export const addOrUpdateVisitComment = (visitID, data) => (dispatch) => {
  dispatch(updateVisitComment());
  return visitsApi
    .updateVisitComment(visitID, data)
    .then((res) => {
      dispatch(updateVisitCommentSucces(res.data));
    })
    .catch((err) => {
      dispatch(updateVisitCommentFailure(errorParser.parseError(err).message));
    });
};
