import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "../Button";
import Icon from "../Icon";

const Autocomplete = ({ suggestions, getDoctorId, reset }) => {
  const [state, setState] = useState({
    activeOption: 0,
    filteredOptions: [],
    showOptions: false,
    userInput: "",
    id: null,
  });

  const onChange = (e) => {
    const userInput = e.currentTarget.value;

    const filteredOptions = suggestions?.filter(
      (optionName) =>
        optionName.user.firstName
          .toLowerCase()
          .indexOf(userInput.toLowerCase()) > -1 ||
        optionName.user.lastName
          .toLowerCase()
          .indexOf(userInput.toLowerCase()) > -1 ||
        optionName?.title.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    setState({
      activeOption: 0,
      filteredOptions,
      showOptions: true,
      userInput: e.currentTarget.value,
      id: e.target.getAttribute("data-id"),
    });
  };

  const onSelectdoctor = (doctorID, e) => {
    setState({
      activeOption: 0,
      filteredOptions: [],
      showOptions: false,
      userInput: e.currentTarget.innerText,
      id: doctorID,
    });
    getDoctorId(doctorID);
  };

  const onKeyDown = (e) => {
    const { activeOption, filteredOptions } = state;

    if (e.keyCode === 13) {
      setState({
        activeOption: 0,
        showOptions: false,
        userInput: [
          filteredOptions[activeOption]?.title,
          filteredOptions[activeOption]?.user?.firstName,
          filteredOptions[activeOption]?.user?.lastName,
        ].join(" "),
        id: e.target.getAttribute("data-id"),
      });
      filteredOptions[activeOption]?.id &&
        getDoctorId(filteredOptions[activeOption].id);
    } else if (e.keyCode === 38) {
      if (activeOption === 0) {
        return;
      }
      setState({ ...state, activeOption: activeOption - 1 });
    } else if (e.keyCode === 40) {
      if (activeOption === filteredOptions.length - 1) {
        return;
      }
      setState({ ...state, activeOption: activeOption + 1 });
    }
  };

  const handleReset = () => {
    setState({
      activeOption: 0,
      filteredOptions: [],
      showOptions: false,
      userInput: "",
      id: null,
    });
    reset();
  };

  let optionList;
  if (state.showOptions && state.userInput) {
    if (state.filteredOptions.length) {
      optionList = (
        <ul className="options">
          {state.filteredOptions.map((optionName, index) => {
            let className;
            if (index === state.activeOption) {
              className = "option-active";
            }
            return (
              <li
                className={className}
                key={optionName.id}
                data-id={optionName?.id}
                onClick={(e) => onSelectdoctor(optionName.id, e)}
              >
                {optionName?.title} {optionName?.user?.firstName}{" "}
                {optionName?.user?.lastName}
              </li>
            );
          })}
        </ul>
      );
    } else {
      optionList = <div className="no-options">Brak danych</div>;
    }
  }

  return (
    <div className="search">
      <input
        type="text"
        className="search-box"
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={state.userInput}
        placeholder="Wyszukaj wizyty weterynarza"
      />
      {state.userInput.length > 0 && (
        <Button
          className="padding-0 search-btn close-btn"
          htmlType="submit"
          onClick={handleReset}
        >
          <Icon name="close" color="white" />
        </Button>
      )}
      {optionList}
    </div>
  );
};

Autocomplete.propTypes = {
  suggestions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string,
      user: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
      }),
    })
  ),
  getDoctorId: PropTypes.func,
  reset: PropTypes.func,
};

export default Autocomplete;
