import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "../Button";
import Input from "../Input";
import Icon from "../Icon";
import { bool, array, string } from "prop-types";

const SearchWidget = ({
  data,
  loading,
  defaultCity = "",
  defaultType = "",
}) => {
  const history = useHistory();

  const [city, setCity] = useState(defaultCity);
  const [selectedType, setSelectedType] = useState(defaultType);

  const onSearch = useCallback(() => {
    history.push({
      pathname: `/placowki/${city || "-"}/${selectedType}`,
      search: "",
    });
  }, [city, selectedType, history]);

  const onReset = () => {
    setCity("");
    setSelectedType("");
    history.push({
      pathname: "/placowki",
      search: "",
    });
  };

  return (
    <div className="search-widget">
      <Input
        placeholder="Wpisz miejscowość"
        className="input"
        value={city === "-" ? "" : city}
        onChange={(e) => setCity(e.target.value)}
      />
      {city && city !== "-" && (
        <Button
          className="transparent padding-0 search-widget__close"
          onClick={() => onReset()}>
          <Icon name="close" color="white" />
        </Button>
      )}
      <span>
        <Icon name="arrow_down" color="greyish" />
        <select
          value={selectedType}
          className="select"
          onChange={(e) => setSelectedType(e.target.value)}>
          <option value="">Wszystkie</option>;
          {data?.map((type) => {
            return (
              <option key={type.id} value={type.id}>
                {type.type}
              </option>
            );
          })}
        </select>
      </span>
      <Button
        loading={loading}
        className="small search-widget__submit"
        onClick={() => onSearch()}>
        Szukaj
      </Button>
    </div>
  );
};

export default SearchWidget;

SearchWidget.propTypes = {
  loading: bool,
  data: array.isRequired,
  defaultCity: string,
  defaultType: string,
};
