import API from "./ApiConfig";

export const userApi = {
  login,
  register,
  getProfile,
  updateUser,
  deleteUser,
  forgotPassword,
  loginWithProvider,
  resetPassword,
};

function login(identifier, password) {
  return API.post("auth/local", {
    identifier,
    password,
  });
}

function register(firstName, lastName, email, phone, password) {
  return API.post("auth/local/register", {
    firstName,
    lastName,
    email,
    phone,
    password,
  });
}

function getProfile() {
  return API.get("users/me");
}

function updateUser(id, user) {
  return API.put(`users/${id}`, user);
}

function deleteUser(id) {
  return API.delete(`users/${id}`);
}

function forgotPassword(email) {
  return API.post("auth/forgot-password", { email });
}

function loginWithProvider(provider, callback) {
  return API.get(`auth/${provider}/callback${callback}`);
}

function resetPassword(code, password, passwordConfirmation) {
  return API.post("/auth/reset-password", {
    code,
    password,
    passwordConfirmation,
  });
}
