import { specializationsApi } from "../api/SpecializationsApi";
import { errorParser } from "../api/ErrorParser";
import * as types from "./SpecializationsConstatns";

export const fetchSpecializations = () => ({
  type: types.FETCH_SPECIALIZATIONS,
});

export const saveSepcializations = (data) => ({
  type: types.FETCH_SPECIALIZATIONS_SUCCESS,
  payload: data,
});

export const errorSepcializations = (errorMessage) => ({
  type: types.FETCH_SPECIALIZATIONS_FAILURE,
  payload: errorMessage,
});

export const getSpecializations = () => (dispatch) => {
  dispatch(fetchSpecializations());
  specializationsApi
    .getSpecializations()
    .then(async (res) => {
      dispatch(saveSepcializations(res.data));
    })
    .catch((err) => {
      dispatch(errorSepcializations(errorParser.parseError(err).message));
    });
};
