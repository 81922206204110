import React, { useMemo } from "react";

import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import { reserveVisitReset } from "../../actions/VisitsActions";

import { func } from "prop-types";

import RequestStatusModal from "../Modals/RequestStatusModal";

import queryString from "query-string";

const VisitModal = ({ onClose }) => {
  const dispatch = useDispatch();

  const location = useLocation();

  const visitId = useMemo(() => {
    return queryString.parse(location?.search)?.visit;
  }, [location]);

  const onCloseModal = () => {
    dispatch(reserveVisitReset());
    onClose();
  };

  return (
    <RequestStatusModal
      close={() => onCloseModal()}
      successComponent={
        <>
          <h2>Wizyta {visitId ? "przełożona" : "zarezerwowana"} pomyślnie!</h2>
          <p>Na Twój adres e-mail wysłaliśmy potwierdzenie.</p>
          <p>
            Podgląd szczegółów znajdziesz w zakładce
            <br />
            <Link to="/panel-uzytkownika">Panel użytkownika &gt; Wizyty</Link>
          </p>
        </>
      }
      failureComponent={<p>Coś poszło nie tak, spróbuj ponowie później.</p>}
    />
  );
};

export default VisitModal;
VisitModal.propTypes = {
  onClose: func,
};
