import React from "react";
import Icon from "../Icon";
import Loader from "react-loader-spinner";
import { bool, string, func, oneOfType, arrayOf, node } from "prop-types";

const Button = ({
  className,
  disabled = false,
  htmlType = "button",
  children,
  onClick,
  refProp,
  iconColor,
  icon,
  loading = false,
}) => {
  return (
    <button
      ref={refProp}
      disabled={!!disabled || !!loading}
      className={[
        "button",
        className ? className : "",
        disabled && "disabled",
      ].join(" ")}
      type={htmlType}
      onClick={(e) => onClick && onClick(e)}
    >
      {loading ? (
        <Loader
          type="Oval"
          color={iconColor || "#fff"}
          height={30}
          width={30}
        />
      ) : (
        <>
          {icon && (
            <Icon color={iconColor ? iconColor : "orange"} name={icon} />
          )}
          <span>{children}</span>
        </>
      )}
    </button>
  );
};

export default Button;

Button.propTypes = {
  className: string,
  disabled: bool,
  htmlType: string,
  children: oneOfType([arrayOf(node), node]),
  onClick: func,
  refProp: string,
  iconColor: string,
  icon: string,
  loading: bool,
};
