import { createContext } from "react";

export const LoginModalContext = createContext({
  showLoginModal: false,
  setShowLoginModal: (show) => show,
});

export const RegisterModalContext = createContext({
  showRegisterModal: false,
  setShowRegisterModal: (show) => show,
});

export const RemindPasswordContext = createContext({
  showRemindPasswordModal: false,
  setShowRemindPasswordModal: (show) => show,
});

export const NewPasswordContext = createContext({
  showRemindPasswordModal: false,
  setShowRemindPasswordModal: (show) => show,
});
