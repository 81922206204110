import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getDoctors } from "../../actions/DoctorsActions";
import { getSpecializations } from "../../actions/SpecializationsActions";
import { getPlaces } from "../../actions/PlacesActions";

import EmployeeBox from "./employeeBox";
import Button from "../../components/Button";
import EmployeeModal from "./employeeModal";
import HoursModal from "./employeeHours";

const Employees = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);
  const doctors = useSelector((state) => state.doctors.doctors);

  const [showDoctorModal, setShowDoctorModal] = useState(false);
  const [showHoursModal, setShowHoursModal] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState(null);

  const places = useMemo(() => {
    return user?.doctors?.map((doctor) => doctor.place) || [];
  }, [user]);

  console.log("doctors: ", doctors);
  console.log("user: ", user);
  console.log("places: ", places);

  const onEditDoctor = (doctor, hours) => {
    setSelectedDoctor(doctor);
    if (hours) {
      setShowHoursModal(true);
    } else {
      setShowDoctorModal(true);
    }
  };

  const onCloseModal = () => {
    setSelectedDoctor(null);
    setShowDoctorModal(false);
    setShowHoursModal(false);
  };

  useEffect(() => {
    dispatch(getSpecializations());
    dispatch(getPlaces());
  }, [dispatch]);

  useEffect(() => {
    if (places?.length > 0) {
      dispatch(getDoctors(places));
    }
  }, [places, dispatch]);

  useEffect(() => {
    if (!doctors?.loading && !doctors?.error) {
      onCloseModal();
    }
  }, [doctors]);

  return (
    <div className="employees-page">
      <div className="container">
        <div className="employees-page__head">
          <h1>Pracownicy</h1>
          <Button onClick={() => setShowDoctorModal(true)}>
            Dodaj pracownika
          </Button>
          {showDoctorModal && (
            <EmployeeModal
              loading={doctors.loading}
              data={selectedDoctor}
              hideModal={() => onCloseModal()}
            />
          )}

          {showHoursModal && (
            <HoursModal
              loading={doctors.loading}
              data={selectedDoctor}
              hideModal={() => onCloseModal()}
            />
          )}
        </div>

        <div className="content">
          <div className="row">
            {doctors?.data?.map((doctor, index) => {
              return (
                <div key={index} className="col-12">
                  <EmployeeBox
                    data={doctor}
                    onEdit={(doctor, hours) => onEditDoctor(doctor, hours)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Employees;
