import React, { useState, useEffect, useCallback } from "react";
import { ViewState } from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  DayView,
  WeekView,
  MonthView,
  Appointments,
  Toolbar,
  DateNavigator,
  TodayButton,
  CurrentTimeIndicator,
} from "@devexpress/dx-react-scheduler-material-ui";
import RadioInput from "../../components/FormComponents/Radio";
import Icon from "../../components/Icon";
import { schegulerOptions } from "../../values/options";
import { useDispatch, useSelector } from "react-redux";
import { getVisits } from "../../actions/VisitsActions";
import VisitModal from "../../components/Modals/VisitModal";

const VetPanelVisits = () => {
  const dispatch = useDispatch();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentViewName, setCurrentViewName] = useState("Today");

  const auth = useSelector((state) => state.auth);
  const visits = useSelector((state) => state.visits);

  const [modalData, setModalData] = useState(null);

  const newVisitsKeys = [
    ...visits?.data?.map(
      ({ start: startDate, end: endDate, desc: title, ...rest }) => ({
        startDate,
        endDate,
        title,
        ...rest,
      })
    ),
  ];

  useEffect(() => {
    auth.user.doctors.length && dispatch(getVisits());
  }, [dispatch, auth.user]);

  const handleShowModal = useCallback(
    (appointmentData) => setModalData(appointmentData),
    [setModalData]
  );

  const TableCell = ({ data, ...rest }) => {
    return (
      <Appointments.Appointment
        className="appointment"
        onClick={() => handleShowModal(data)}
        {...rest}
      >
        <h2 className="title">{data?.title}</h2>
        <div className="description">{data?.description}</div>

        <div className="row no-gutters justify-content-start align-items-center">
          <Icon
            name={
              data?.sex === "female"
                ? "female_icon"
                : data?.sex === "male" && "male_icon"
            }
            color="orange"
          />
          <div className="race">{data?.race}</div>,<div className="time"></div>
        </div>
      </Appointments.Appointment>
    );
  };

  return (
    <div className="vet-page-visits">
      <div className="container">
        <h1 className="title">Wizyty</h1>
        <p>Harmonogram zarezerwowanych terminów</p>

        <div className="content">
          <Scheduler locale="pl-PL" data={newVisitsKeys} height={800}>
            <ViewState
              currentDate={currentDate}
              currentViewName={currentViewName}
              onCurrentDateChange={(date) => setCurrentDate(date)}
            />

            <div className="custom-fields row justify-content-between no-gutters">
              <RadioInput
                name="scheduler-view"
                defaultValue={currentViewName}
                onInputChange={(name) => {
                  setCurrentViewName(name);
                  setCurrentDate(
                    schegulerOptions.find((item) => item.value === name)?.date
                  );
                }}
                options={schegulerOptions}
              />
            </div>

            <Toolbar />

            <DayView
              name="Today"
              displayName="Dzisiaj"
              startDate={new Date()}
              startDayHour={6}
            />
            <DayView
              name="Tomorrow"
              displayName="Jutro"
              startDate={new Date().setDate(new Date().getDate() + 1)}
              startDayHour={6}
            />
            <DayView
              name="DayAfterTomorrow"
              displayName="Pojutrze"
              startDate={new Date().setDate(new Date().getDate() + 2)}
              startDayHour={6}
            />
            <WeekView name="Week" startDayHour={6} />
            <MonthView name="Month" />

            <DateNavigator />

            <TodayButton
              messages={{
                today: "Dziś",
              }}
            />

            <Appointments appointmentComponent={TableCell} />
            <CurrentTimeIndicator
              shadePreviousCells={true}
              shadePreviousAppointments={true}
              updateInterval={1000}
            />
          </Scheduler>

          <VisitModal
            isOpen={modalData?.id ? true : false}
            visits={visits?.data}
            hide={() => setModalData(null)}
            modalData={modalData}
          />
        </div>
      </div>
    </div>
  );
};

export default VetPanelVisits;
