import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import ArticleItem from "../../components/ArticleItem/ArticleItem";
import * as articlesActions from "../../actions/ArticlesActions";

const ArticlesPage = () => {
  const articles = useSelector((state) => state.articles);
  const dispatch = useDispatch();

  const [sortBy, setSrotBy] = useState("newest");

  useEffect(() => {
    if (articles.list.length === 0 && !articles?.loading) {
      dispatch(articlesActions.getArticles());
    }
  }, [dispatch, articles]);

  const sortedArticles = useMemo(() => {
    if (sortBy === "alphLower") {
      return articles?.list?.sort((a, b) => a.title.localeCompare(b.title));
    } else if (sortBy === "alphHighest") {
      return articles?.list?.sort((a, b) => b.title.localeCompare(a.title));
    } else if (sortBy === "oldest") {
      return articles?.list?.sort(
        (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
      );
    }
    return articles?.list?.sort(
      (a, b) => new Date(a.updated_at) - new Date(b.updated_at)
    );
  }, [articles, sortBy]);

  return (
    <div className="articles-page">
      <div className="container">
        <h1 className="title">Dobrze wiedzieć</h1>

        <div className="sort-container">
          <span>Sortuj wg </span>
          <select onChange={(e) => setSrotBy(e.target.value)}>
            <option value="newest">Najnowsze</option>
            <option value="oldest">Najstarsze</option>
            <option value="alphLower">A - Z</option>
            <option value="alphHighest">Z - A</option>
          </select>
        </div>

        <div className="row justify-content-between">
          {sortedArticles?.length > 0 &&
            sortedArticles?.map((item) => (
              <ArticleItem size="large" article={item} key={item.id} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default ArticlesPage;
