import React, { useState, useEffect, useCallback, Fragment } from "react";

import { useDispatch } from "react-redux";

import { editDoctor } from "../../actions/DoctorsActions";

import Button from "../../components/Button";
import Modal from "react-modal";
import InputComponent from "../../components/Input";

import { firstUpperCase } from "../../utils/utils";

import { shape, bool, string, number, func } from "prop-types";

import moment from "moment";

const EmployeeHours = ({ hideModal, data, loading }) => {
  const dispatch = useDispatch();

  const weekDays = moment().locale("en").localeData().weekdays();
  const plWeekDays = moment.weekdays();

  const [formData, setFormData] = useState(null);

  const updaeFormData = (key, val) => {
    setFormData({ ...formData, [key]: `${val}:00` });
  };

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(editDoctor(data.id, { ...data, workingHours: formData }));
    },
    [formData, data, dispatch]
  );

  useEffect(() => {
    if (data?.id) {
      setFormData(data.workingHours);
    }
  }, [data]);

  return (
    <Modal
      isOpen={true}
      className="add-new-employee add-new-employee--hours modal"
      overlayClassName="modal-overlay"
      ariaHideApp={false}
      onRequestClose={hideModal}
    >
      <form onSubmit={!loading && onSubmit}>
        <div className="modal-header">
          <h2>
            Godziny pracy {data?.title} {data?.user?.firstName}{" "}
            {data?.user?.lastName}
          </h2>
          <div>
            <Button className="transparent" onClick={hideModal}>
              Anuluj
            </Button>
            <Button loading={loading} className="transparent" htmlType="submit">
              {data?.id ? "Zapisz" : "Dodaj"}
            </Button>
          </div>
        </div>

        <div className="modal-content">
          <div className="row">
            {formData &&
              weekDays?.map((day, index) => {
                return (
                  <Fragment key={index}>
                    <div className="col-6">
                      <small>{firstUpperCase(plWeekDays[index])} od:</small>
                      <InputComponent
                        placeholder="00:00"
                        name="title"
                        value={formData[`${day.toLowerCase()}From`] || ""}
                        type="time"
                        onChange={(e) => {
                          updaeFormData(
                            `${day.toLowerCase()}From`,
                            e.target.value
                          );
                        }}
                      />
                    </div>
                    <div className="col-6">
                      <small>{firstUpperCase(plWeekDays[index])} do:</small>

                      <InputComponent
                        placeholder="00:00"
                        name="title"
                        value={formData[`${day.toLowerCase()}To`] || ""}
                        type="time"
                        onChange={(e) => {
                          updaeFormData(
                            `${day.toLowerCase()}To`,
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </Fragment>
                );
              })}
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default EmployeeHours;

EmployeeHours.propTypes = {
  hideModal: func,
  showStatusModal: func,
  loading: bool,
  data: shape({
    created_at: string.isRequired,
    displayPhone: string,
    hidden: bool,
    id: number.isRequired,
    place: shape({}),
    title: string,
    updated_at: string.isRequired,
    user: shape({}),
    workingHours: shape({}),
  }).isRequired,
};
