export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_PRODUCTS_FAILURE = "FETCH_PRODUCTS_ERROR";

export const FETCH_TYPES_PRODUCTS = "FETCH_TYPES_PRODUCTS";
export const FETCH_TYPES_PRODUCTS_SUCCESS = "FETCH_TYPES_PRODUCTS_SUCCESS";
export const FETCH_TYPES_PRODUCTS_FAILURE = "FETCH_TYPES_PRODUCTS_ERROR";

export const FETCH_PRODUCT = "FETCH_PRODUCT";
export const FETCH_PRODUCT_SUCCESS = "FETCH_PRODUCT_SUCCESS";
export const FETCH_PRODUCT_FAILURE = "FETCH_PRODUCT_ERROR";

export const FETCH_FEATURED_PRODUCTS = "FETCH_FEATURED_PRODUCTS";
export const FETCH_FEATURED_PRODUCTS_FAILURE =
  "FETCH_FEATURED_PRODUCTS_FAILURE";
export const FETCH_FEATURED_PRODUCTS_SUCCESS =
  "FETCH_FEATURED_PRODUCTS_SUCCESS";
