import * as type from "./NotificationsConstants";
import { notificationsApi } from "../api/NotificationsApi";
import { errorParser } from "../api/ErrorParser";

export const fetchDoctorNotifications = () => ({
  type: type.FETCH_DOCTOR_NOTIFICATIONS,
});

export const fetchDoctorNotificationsSuccess = (data) => ({
  type: type.FETCH_DOCTOR_NOTIFICATIONS_SUCCESS,
  payload: data,
});

export const fetchDoctorNotificationsFailure = (data) => ({
  type: type.FETCH_DOCTOR_NOTIFICATIONS_FAILURE,
  payload: data,
});

export const getDoctorNotifications = (doctorId, sort) => (dispatch) => {
  dispatch(fetchDoctorNotifications());
  notificationsApi
    .getDoctorNotifications(doctorId, sort)
    .then((res) => {
      dispatch(fetchDoctorNotificationsSuccess(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchDoctorNotificationsFailure(errorParser.parseError(err).message)
      );
    });
};

export const updateDoctorNotification = () => ({
  type: type.UPDATE_DOCTOR_NOTIFICATION,
});

export const updateDoctorNotificationSuccess = (data) => ({
  type: type.UPDATE_DOCTOR_NOTIFICATION_SUCCESS,
  payload: data,
});

export const updateDoctorNotificationFailure = (data) => ({
  type: type.UPDATE_DOCTOR_NOTIFICATION_FAILURE,
  payload: data,
});

export const updateNotification = (doctorId, notificationId, data) => (
  dispatch
) => {
  dispatch(updateDoctorNotification());
  notificationsApi
    .updateNotification(doctorId, notificationId, data)
    .then((res) => {
      dispatch(updateDoctorNotificationSuccess(res.data));
    })
    .catch((err) => {
      dispatch(
        updateDoctorNotificationFailure(errorParser.parseError(err).message)
      );
    });
};
