import React from "react";
import UserPanelLayout from "../../layouts/userPanel";

const Settings = () => {
  return (
    <UserPanelLayout>
      <div className="head">
        <h2 className="title">Ustawienia</h2>
      </div>
      <section className="settings-section">ustawienia</section>
    </UserPanelLayout>
  );
};
export default Settings;
