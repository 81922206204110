import { placesApi } from "../api/PlacesApi";
import { errorParser } from "../api/ErrorParser";
import * as types from "./PlacesConstants";

export const fetchPlaces = () => ({
  type: types.FETCH_PLACES,
});

export const fetchTypes = () => ({
  type: types.FETCH_TYPES,
});

export const savePlaces = (data) => ({
  type: types.FETCH_PLACES_SUCCESS,
  payload: data,
});

export const saveTypes = (data) => ({
  type: types.FETCH_TYPES_SUCCESS,
  payload: data,
});

export const errorPlaces = (errorMessage) => ({
  type: types.FETCH_PLACES_FAILURE,
  payload: errorMessage,
});

export const errorTypes = (errorMessage) => ({
  type: types.FETCH_PLACES_FAILURE,
  payload: errorMessage,
});

export const getPlaces = (
  sort = "created_at:DESC",
  limit = 10,
  city,
  typeId
) => (dispatch) => {
  dispatch(fetchPlaces());
  placesApi
    .getAllPlaces(sort, limit, city, typeId)
    .then(async (res) => {
      dispatch(savePlaces(res.data));
    })
    .catch((err) => {
      dispatch(errorPlaces(errorParser.parseError(err).message));
    });
};

export const getTypes = () => (dispatch) => {
  dispatch(fetchTypes());
  placesApi
    .getTypesOfPalces()
    .then(async (res) => {
      dispatch(saveTypes(res.data));
    })
    .catch((err) => {
      dispatch(errorTypes(errorParser.parseError(err).message));
    });
};

export const searchPlaces = () => ({
  type: types.FETCH_PLACES,
});

export const saveSearchResults = (data) => ({
  type: types.FETCH_PLACES_SUCCESS,
  payload: data,
});

export const errorSearchPlaces = (msg) => ({
  type: types.FETCH_PLACES_FAILURE,
  payload: msg,
});

export const search = (city, typeId) => (dispatch) => {
  dispatch(searchPlaces());
  placesApi
    .search(city, typeId)
    .then((res) => {
      dispatch(saveSearchResults(res.data));
    })
    .catch((err) => {
      dispatch(errorSearchPlaces(errorParser.parseError(err).message));
    });
};

export const fetchPlaceDetails = () => ({
  type: types.FETCH_PLACE_DETAILS,
});

export const savePlaceDetails = (data) => ({
  type: types.FETCH_PLACE_DETAILS_SUCCESS,
  payload: data,
});

export const erroPlaceDetails = (data) => ({
  type: types.FETCH_PLACE_DETAILS_FAILURE,
  payload: data,
});
