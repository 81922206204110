import * as actions from "../actions/NotificationsConstants";

const initialState = {
  data: [],
  loading: false,
  errorMessage: "",
};

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_DOCTOR_NOTIFICATIONS:
    case actions.UPDATE_DOCTOR_NOTIFICATION:
      return {
        ...state,
        loading: true,
      };
    case actions.FETCH_DOCTOR_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case actions.FETCH_DOCTOR_NOTIFICATIONS_FAILURE:
    case actions.UPDATE_DOCTOR_NOTIFICATION_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };

    case actions.UPDATE_DOCTOR_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data].map((notification, index) => {
          if (notification.id === action.payload.id) {
            return action.payload;
          }
          return notification;
        }),
        errorMessage: "",
      };

    default:
      return state;
  }
};

export default notificationsReducer;
