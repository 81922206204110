import React, { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import * as placesActions from "../../actions/PlacesActions";

import PlaceItem from "../PlaceItem/PlaceItem";
import Slider from "react-slick";

import { settings } from "../../values/SlidersSettings";

const PlacesSection = () => {
  const dispatch = useDispatch();

  const places = useSelector((state) => state.places);

  const sSettings = { ...settings, slidesToShow: 4 };

  useEffect(() => {
    dispatch(placesActions.getPlaces("created_at:DESC", 4));
  }, [dispatch]);

  return (
    <div className="section section--places">
      <header>
        <h1 className="title">Przyjazne miejsca pupilom</h1>
        <Link className="link-more" to="/placowki">
          Więcej
        </Link>
      </header>

      <Slider
        className={
          places.placesList?.length < sSettings.slidesToShow ? "no-clone" : ""
        }
        {...sSettings}
      >
        {places.placesList.map((item, index) => (
          <PlaceItem col={"24"} item={item} key={index} />
        ))}
      </Slider>
    </div>
  );
};

export default PlacesSection;
