import React, { useState, useMemo, useCallback } from "react";
import Modal from "react-modal";
import Icon from "../Icon";
import Button from "../Button";
import HistoryItem from "../HistoryItem";
import moment from "moment";
import { useDispatch } from "react-redux";
import {
  addOrUpdateVisitComment,
  cancelVisit,
} from "../../actions/VisitsActions";
import strings from "../../values/Strings";
import PropTypes from "prop-types";
import Countdown from "../Countdown";

const VisitModal = ({ isOpen, hide, visits, modalData }) => {
  const dispatch = useDispatch();
  const startDate = modalData?.startDate;
  const endDate = modalData?.endDate;

  const formatedDate = useMemo(() => {
    return moment(startDate)
      .format(strings.hour__day_name__full_date)
      .split(" ");
  }, [startDate]);

  const endHour = useMemo(() => {
    return moment(endDate).format(strings.hours);
  }, [endDate]);

  const [summary, setSummary] = useState("");

  const [error, setError] = useState("");

  const handleCancelVisit = useCallback(() => {
    dispatch(cancelVisit(modalData?.id));
    hide();
  }, [modalData, dispatch, hide]);

  const handleAddVisitSummary = useCallback(() => {
    if (summary.length) {
      dispatch(addOrUpdateVisitComment(modalData.id, summary));
      setSummary("");
      setError("");
    } else {
      setError(strings.error_field_empty);
    }
  }, [dispatch, modalData, summary]);

  const currentVisitSummary = useMemo(() => {
    return visits?.find((visit) => visit?.id === modalData?.id);
  }, [visits, modalData]);

  return (
    <Modal
      isOpen={isOpen}
      overlayClassName="modal-overlay"
      className="visit-modal"
      ariaHideApp={false}
    >
      <Button className="round" onClick={hide}>
        <Icon name="close" color="white" />
      </Button>

      <div className="box-1">
        <div>
          <p>Przewidywany czas wizyty</p>
          <h2 className="date">
            {formatedDate[0]} - {endHour} <span>{formatedDate[1]}</span>,{" "}
            {formatedDate[2]}
          </h2>
        </div>

        <div>
          <p className="description">{modalData?.title}</p>
        </div>
        <h4 className="history__title">Historia wizyt</h4>
        <div className="history">
          {visits
            ?.filter((item) => item?.id !== modalData?.id)
            ?.map((item, index) => (
              <HistoryItem
                key={index}
                date={moment(item.start).format("DD.MM.YYYY HH:MM")}
                description={item?.desc}
                summary={item.comment || undefined}
              />
            ))}
        </div>
      </div>

      <div className="box-2">
        <h4 className="pet">Zwierzak</h4>
        <p className="pet-name">
          Imię: {modalData?.pet?.name || "Nie podano imienia"}
        </p>
        <p className="pet-specie">
          Gatunek: {modalData?.pet?.species || "Nie podano gatunku"}
        </p>
        <p className="pet-gender">
          Płeć: {modalData?.pet?.sex === "female" ? "Samica" : "Samiec"}
        </p>
        <p className="pet-age">
          Data urodzenia:{" "}
          {modalData?.pet?.yearBirth || "Nie podano daty urodzenia"}
        </p>
        <p className="pet-mikrochip">
          Mikroczip:{" "}
          {modalData?.pet?.mikrochipId || "Nie podano numery mikrochipa"}
        </p>
        <p className="pet-neutered">
          Wykastrowany: {modalData?.pet?.neutered ? "Tak" : "Nie"}
        </p>
        <p className="pet-outgoing">
          Wychodzący: {modalData?.pet?.outgoing ? "Tak" : "Nie"}
        </p>

        <br />

        <h4 className="owner">Właściciel</h4>
        <p className="name">
          {modalData?.pet?.user ? (
            <>
              {modalData?.pet?.user?.firstName} {modalData?.pet?.user?.lastName}
            </>
          ) : (
            "Brak imienia i nazwiska"
          )}
        </p>
        <a
          className="phone"
          href={`tel:${
            modalData?.pet?.user?.phone ? modalData?.pet?.user?.phone : ""
          }`}
        >
          Tel.{" "}
          {modalData?.pet?.user?.phone
            ? modalData?.pet?.user?.phone
            : "Brak numeru telefonu"}
        </a>

        <a
          className="mail"
          href={`mailto:${
            modalData?.pet?.user?.email ? modalData?.pet?.user?.email : ""
          }`}
        >
          {modalData?.pet?.user?.email
            ? modalData?.pet?.user?.email
            : "Brak maila"}
        </a>
      </div>

      <div className="box-3">
        <h4>Podsumowaie wizyty</h4>

        {currentVisitSummary?.comment?.length > 0 ? (
          <HistoryItem
            date={moment(currentVisitSummary.start).format(
              strings.full_date_dots__hours
            )}
            description={currentVisitSummary.desc}
            summary={currentVisitSummary?.comment || undefined}
          />
        ) : (
          <>
            <textarea
              rwos="4"
              placeholder="Komentarz weterynarza"
              onChange={(e) => setSummary(e.target.value)}
              value={summary}
            ></textarea>
            {error && <span className="validation-error">{error}</span>}
            <Button onClick={handleAddVisitSummary}>Dodaj</Button>
          </>
        )}
      </div>

      <div className="box-4">
        <h4>Status wizyty</h4>
        {/* todo */}
        <div className={`status--${true ? "waiting" : "done"}`}>Oczekuje</div>
        <div className="time-left">
          Do wizyty pozostało: <br />
          <Countdown startDate={modalData?.startDate} />
        </div>
        <Button className="cancel" onClick={handleCancelVisit}>
          Anuluj wizytę
        </Button>
      </div>
    </Modal>
  );
};

VisitModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  visits: PropTypes.array,
  modalData: PropTypes.object,
};

export default VisitModal;
