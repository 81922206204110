import API from "./ApiConfig";

export const placesApi = {
  getTypesOfPalces,
  getAllPlaces,
  search,
};

function getTypesOfPalces() {
  return API.get("place-types");
}

function getAllPlaces(sort, limit, city, typeId) {
  return API.get("places", {
    params: {
      _sort: sort,
      _limit: limit,
      "address.city_containss": city || undefined,
      "type.id": typeId || undefined,
    },
  });
}

function search(city, typeId) {
  let params = {
    "address.city": city || undefined,
    "type.id": typeId || undefined,
  };
  return API.get("places", { params: params });
}
