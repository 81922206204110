import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getPets } from "../../../actions/PetsActions";

import Pet from "../../../components/Pet";
import Button from "../../../components/Button";
import NewPetModal from "./newPet";
import UserPanelLayout from "../../../layouts/userPanel";

import RequestStatusModal from "../../../components/Modals/RequestStatusModal";

const PetsList = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);
  const pets = useSelector((state) => state.pets);

  const [showModal, setShowModal] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [pet, setPet] = useState({});

  useEffect(() => {
    user.id && dispatch(getPets(user.id));
  }, [dispatch, user.id]);

  return (
    <UserPanelLayout>
      {showModal && (
        <NewPetModal
          hideModal={() => setShowModal(false)}
          showStatusModal={() => setShowStatusModal(true)}
          currentPet={pet}
        />
      )}

      <div className="head">
        <h2 className="title">Moi pupile</h2>
        <Button onClick={() => setShowModal(true)}>Dodaj nowego pupila</Button>
      </div>

      {pets?.data.map((item, index) => (
        <Pet
          key={index}
          data={item}
          userId={user.id}
          getPet={(data) => setPet(data)}
          openModal={() => setShowModal(true)}
        />
      ))}

      {pets?.success && showStatusModal && (
        <RequestStatusModal
          isSuccess={pets?.success}
          close={() => setShowStatusModal(false)}
          successText="Pupil został dodany pomyslnie."
          failureText="Nie udało się dodać pupila, spróbuj ponownie później."
        />
      )}
    </UserPanelLayout>
  );
};
export default PetsList;
