import React from "react";

import Modal from "react-modal";
import Icon from "../Icon";
import Button from "../Button";

import { bool, func, oneOfType, arrayOf, node } from "prop-types";

const RequestStatusModal = ({
  isSuccess = true,
  close,
  successComponent,
  failureComponent,
}) => {
  return (
    <Modal
      isOpen={true}
      className="success-modal modal"
      overlayClassName="modal-overlay"
      ariaHideApp={false}
      onRequestClose={close}
    >
      {isSuccess ? (
        <>
          <Icon name="success" color="green" />
          <div>{successComponent}</div>
        </>
      ) : (
        <>
          <Icon name="failure" color="red" />
          <div>{failureComponent || ""}</div>
        </>
      )}
      <Button onClick={close}>Zamknij</Button>
    </Modal>
  );
};

export default RequestStatusModal;
RequestStatusModal.propTypes = {
  isSuccess: bool,
  close: func.isRequired,
  successComponent: oneOfType([arrayOf(node), node]),
  failureComponent: oneOfType([arrayOf(node), node]),
};
