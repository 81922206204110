import React, { useMemo } from "react";

import "leaflet/dist/leaflet.css";

import { MapContainer, TileLayer, ZoomControl } from "react-leaflet";

import BoundsWrapper from "./bounds";
import Loader from "react-loader-spinner";

import { bool, array } from "prop-types";

import { defaultCenter, defaultZoom } from "../../values/MapOptions";

const InstancesMap = ({ data, loading }) => {
  const bounds = useMemo(() => {
    if (data?.length > 0) {
      return data.map((place) => place.location.split(", "));
    }
    return [];
  }, [data]);

  return (
    <div className="map-container">
      {loading && (
        <div className="loader-wrapper">
          <Loader type="Oval" color="#f66829" height={100} width={100} />
        </div>
      )}
      <MapContainer
        center={defaultCenter}
        zoom={defaultZoom}
        zoomControl={false}
        scrollWheelZoom={true}
        minZoom={2}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png?api_key=a3d8d08b-5384-4e72-8311-70a7e8a85ee9"
        />
        <ZoomControl position="bottomright" />
        <BoundsWrapper bounds={bounds} markers={data} />
      </MapContainer>
    </div>
  );
};

export default InstancesMap;

InstancesMap.propTypes = {
  loading: bool,
  data: array.isRequired,
};
