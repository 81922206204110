import React, { useState } from "react";
import { string, func } from "prop-types";
import Icon from "../../Icon";

const FileUploader = ({ name, onInputChange, label, img }) => {
  const [currentImg, setCurrentImg] = useState(img ? img : null);
  const [, setValue] = useState(null);

  const onFileUpload = (e) => {
    const file = e.target.files[0];
    setCurrentImg(URL.createObjectURL(file));
    setValue(file);
    onInputChange(file);
  };

  const onDeleteFile = () => {
    setCurrentImg(null);
    setValue(null);
    onInputChange(null);
  };

  return (
    <fieldset className="custom-file-uploader">
      {label && <span className="label">{label}</span>}
      <label>
        {currentImg ? (
          <>
            <div className="custom-file-uploader__img-wrapper">
              <img src={currentImg} alt="pet" />
            </div>
            <button onClick={() => onDeleteFile()}>Usuń</button>
          </>
        ) : (
          <>
            <Icon name="camera" color="light-grey" />
            <input
              onChange={(e) => onFileUpload(e)}
              hidden
              type="file"
              name={name}
              accept="image/*"
            />
            <span className="btn">Dodaj</span>
          </>
        )}
      </label>
    </fieldset>
  );
};

export default FileUploader;

FileUploader.propTypes = {
  name: string.isRequired,
  onInputChange: func.isRequired,
  label: string,
};
