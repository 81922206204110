import API from "./ApiConfig";
import moment from "moment";

export const doctorsApi = {
  getDoctors,
  getDoctorTimeslots,
  getAllDoctors,
  addDoctor,
  editDoctor,
  deleteDoctor,
};

function getDoctors(place, start, end) {
  return API.get("/doctors", { params: { place, "user.role": 3, start, end } });
}

function addDoctor(data) {
  return API.post("/doctors", data);
}

function editDoctor(id, data) {
  return API.put(`/doctors/${id}`, data);
}

function deleteDoctor(id) {
  return API.delete(`/doctors/${id}`);
}

function getDoctorTimeslots(doctorId, date, minutes = 15) {
  const isToday = !moment(date).isAfter(moment(), "days");
  const startRaw = isToday ? moment(date) : moment(date).startOf("day");
  const remainder = minutes - (startRaw.minutes() % minutes);
  const from = startRaw
    .startOf("minute")
    .add(remainder, "minutes")
    .toISOString();

  const to = moment(from)
    .startOf("day")
    .add(1, "day")
    .add(1, "hours")
    .toISOString();

  return API.get(`/doctors/${doctorId}/timeslots`, {
    params: { from, to, minutes },
  });
}

function getAllDoctors() {
  return API.get("/doctors");
}
