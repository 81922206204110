import React from "react";
import { string, number, func, oneOfType, bool } from "prop-types";

const InputComponent = ({
  type = "text",
  className,
  value,
  name,
  placeholder,
  onChange,
  pattern,
  required = false,
  minLength = 0,
}) => {
  return (
    <input
      className={["input-component", className].join(" ")}
      type={type}
      name={name}
      minLength={minLength}
      pattern={pattern}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      autoComplete="on"
      required={required}
    />
  );
};

export default InputComponent;
InputComponent.propTypes = {
  type: string,
  className: string,
  value: oneOfType([number, string]),
  name: string,
  placeholder: string,
  onChange: func,
  pattern: string,
  required: bool,
  minLength: number,
  error: string,
};
