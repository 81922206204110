import React, { useMemo, useEffect } from "react";
import { useLocation } from "react-router-dom";

import "leaflet/dist/leaflet.css";
import { useMap } from "react-leaflet";

import InstanceMarker from "./marker";

import { array } from "prop-types";

import queryString from "query-string";

import { defaultCenter, defaultZoom, maxBounds } from "../../values/MapOptions";

const BoundsWrapper = ({ bounds, markers }) => {
  const map = useMap();

  const location = useLocation();

  useEffect(() => {
    if (map) {
      map.setMaxBounds(maxBounds);
    }
  }, [map]);

  const qData = useMemo(() => {
    return queryString.parse(location?.search);
  }, [location]);

  const selectedPlace = useMemo(() => {
    return qData.place;
  }, [qData]);

  const active = useMemo(() => {
    return (
      selectedPlace &&
      markers?.find((marker) => marker.id === Number(selectedPlace))
    );
  }, [selectedPlace, markers]);

  if (active?.location) {
    const latLng = {
      lat: Number(active.location.split(", ")[0]),
      lng: Number(active.location.split(", ")[1]),
    };
    map.flyTo(latLng, 14);
  } else if (bounds?.length > 0) {
    map.fitBounds(bounds);
  } else {
    map.fitBounds([defaultCenter]);
    map.setZoom(defaultZoom);
  }

  if (markers?.length > 0) {
    return markers?.map((place) => {
      return <InstanceMarker data={place} key={place.id} />;
    });
  }
  return (
    <div className="map-container__no-results">
      <h4>Brak wyników</h4>
    </div>
  );
};

export default BoundsWrapper;

BoundsWrapper.propTypes = {
  bounds: array,
  markers: array,
};
