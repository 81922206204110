export const FETCH_VISITS = "FETCH_VISITS";
export const FETCH_VISITS_SUCCESS = "FETCH_VISITS_SUCCESS";
export const FETCH_VISITS_FAILURE = "FETCH_VISITS_ERROR";

export const FETCH_VISIT = "FETCH_VISIT";
export const FETCH_VISIT_SUCCESS = "FETCH_VISIT_SUCCESS";
export const FETCH_VISIT_FAILURE = "FETCH_VISIT_ERROR";

export const DELETE_VISIT = "DELETE_VISIT";
export const DELETE_VISIT_SUCCESS = "DELETE_VISIT_SUCCESS";
export const DELETE_VISIT_FAILURE = "DELETE_VISIT_ERROR";

export const UPDATE_VISIT_SUCCESS = "UPDATE_VISIT_SUCCESS";
export const UPDATE_VISIT_FAILURE = "UPDATE_VISIT_ERROR";

export const UPDATE_VISIT_COMMENT = "UPDATE_VISIT_COMMENT";
export const UPDATE_VISIT_COMMENT_SUCCESS = "UPDATE_VISIT_COMMENT_SUCCESS";
export const UPDATE_VISIT_COMMENT_FAILURE = "UPDATE_VISIT_COMMENT_ERROR";

export const RESERVE_VISIT = "RESERVE_VISIT";
export const RESERVE_VISIT_SUCCESS = "RESERVE_VISIT_SUCCESS";
export const RESERVE_VISIT_FAILURE = "RESERVE_VISIT_ERROR";
export const RESERVE_VISIT_RESET = "RESERVE_VISIT_RESET";

export const FETCH_ALL_DOCTOR_VISITS = "FETCH_ALL_DOCTOR_VISITS";
export const FETCH_ALL_DOCTOR_VISITS_SUCCESS =
  "FETCH_ALL_DOCTOR_VISITS_SUCCESS";
export const FETCH_ALL_DOCTOR_VISITS_FAILURE = "FETCH_ALL_DOCTOR_VISITS_ERROR";
