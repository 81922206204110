import React, { useState } from "react";
import { number, string, func } from "prop-types";

const RangeSlider = ({
  name,
  max,
  min,
  unit = null,
  onInputChange,
  label,
  defaultValue = undefined,
}) => {
  const [value, setValue] = useState(defaultValue || (max + min) / 2);
  const [currPos, setCurrPos] = useState(
    defaultValue
      ? Number(((defaultValue - min) * 100) / (max - min))
      : Number(((value - min) * 100) / (max - min))
  );

  const onChangeValue = (val) => {
    setValue(val);
    setCurrPos(Number(((val - min) * 100) / (max - min)));
    onInputChange(val);
  };

  return (
    <div className="custom-range">
      {label && <span className="label">{label}</span>}
      <label>
        <input
          value={value}
          min={min}
          max={max}
          name={name}
          onChange={(e) => onChangeValue(e.target.value)}
          type="range"
        />
        <div>
          <span
            style={{
              marginLeft: `calc(${currPos}% - 50px)`,
            }}
            className="custom-range__value"
          >
            {value} {unit}
          </span>
          <span
            style={{
              marginLeft: `calc(${currPos}% - 27px)`,
            }}
            className="custom-range__thumb"
          />
        </div>
      </label>
    </div>
  );
};

export default RangeSlider;

RangeSlider.propTypes = {
  name: string.isRequired,
  max: number.isRequired,
  min: number.isRequired,
  unit: string,
  onInputChange: func.isRequired,
  label: string,
  defaultValue: number,
};
