import React, { useState, useEffect, useCallback, useMemo } from "react";
import { ViewState, EditingState } from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  Appointments,
  AppointmentForm,
  WeekView,
  EditRecurrenceMenu,
  AllDayPanel,
  DragDropProvider,
  DateNavigator,
  MonthView,
  TodayButton,
  ViewSwitcher,
  Toolbar,
  CurrentTimeIndicator,
} from "@devexpress/dx-react-scheduler-material-ui";
import { useDispatch, useSelector } from "react-redux";
import strings from "../../values/Strings";
import { getDoctors } from "../../actions/DoctorsActions";
import {
  reserveVisit,
  updateVisit,
  cancelVisit,
} from "../../actions/VisitsActions";
import { getPets } from "../../actions/PetsActions";
import { getVisits, getDoctorVisits } from "../../actions/VisitsActions";
import Select from "../../components/FormComponents/Select";
import Autocomplete from "../../components/Autocomplete";

const VetPanelPlanned = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const pets = useSelector((state) => state.pets);
  const doctors = useSelector((state) => state.doctors.doctors);
  const visits = useSelector((state) => state.visits);

  const [currentDate, setCurrentDate] = useState(new Date());
  const [pet, setPet] = useState(pets?.data[0]?.id);
  const [doctor, setDoctor] = useState(doctors?.data && doctors?.data[0]?.id);
  const [appointment, setAppointment] = useState(null);

  const places = useMemo(() => {
    return auth?.user?.doctors.map(
      (doctor) => (doctor.place && doctor.place) || []
    );
  }, [auth.user.doctors]);

  useEffect(() => {
    doctors?.data?.length > 0 && setDoctor(doctors?.data[0]?.id);
    pets?.data.length > 0 && setPet(pets?.data[0].id);
  }, [pets.data, doctors.data]);

  useEffect(() => {
    places.length > 0 && dispatch(getDoctors(places));
    places.length > 0 && dispatch(getPets(places));
  }, [places, dispatch]);

  useEffect(() => {
    auth.user.doctors.length > 0 && dispatch(getVisits());
  }, [dispatch, auth.user.doctors.length]);

  const commitChanges = useCallback(
    (data, appointment) => {
      const { added, changed, deleted } = data;

      if (added) {
        const data = {
          start: added.startDate,
          end: added.endDate,
          pet: Number(pet),
          doctor: Number(doctor),
          desc: added.title,
          comment: added.notes,
        };
        dispatch(reserveVisit(data));
      }

      if (changed) {
        const key = Object.keys(changed)[0];

        const data = {
          start: changed[key]?.startDate || appointment.startDate,
          end: changed[key].endDate || appointment.endDate,
          pet: Number(pet),
          doctor: Number(doctor),
          desc: changed[key].title || appointment.desc,
          comment: changed[key].notes || appointment.comment,
        };

        dispatch(updateVisit(key, data));
      }

      if (deleted !== undefined) {
        dispatch(cancelVisit(deleted));
      }
    },
    [dispatch, pet, doctor]
  );

  const TableCell = ({ data, ...rest }) => {
    return <Appointments.Appointment className="appointment" {...rest} />;
  };

  const DateTimeEdit = ({ ...rest }) => {
    return (
      <AppointmentForm.DateEditor
        {...rest}
        format={strings.full_date_and_hour}
        ampm={false}
      />
    );
  };

  const SwitcherComponent = ({ ...rest }) => {
    return (
      <ViewSwitcher.Switcher
        {...rest}
        availableViews={[
          { name: "Week", displayName: "Tydzień" },
          { name: "Month", displayName: "Miesiąc" },
        ]}
      />
    );
  };

  const LayoutComponent = ({ ...rest }) => {
    return (
      <AppointmentForm.Layout className="appointmentForm-layout" {...rest}>
        <div className="custom-fields">
          <fieldset>
            <Select
              disabled={!doctors?.data?.length > 0}
              name="doctor"
              defaultValue={doctor}
              options={doctors?.data
                .filter((doctor) => doctor.user !== null)
                .map((doctor) => {
                  return {
                    ...doctor,
                    name: [
                      doctor?.title,
                      doctor?.user?.firstName,
                      doctor?.user?.lastName,
                    ].join(" "),
                    value: doctor.id,
                  };
                })}
              onInputChange={(selected) => setDoctor(selected)}
            />
          </fieldset>
          <fieldset>
            <Select
              disabled={!pets?.data?.length > 0}
              name="pet"
              defaultValue={pet}
              options={pets?.data?.map((pet) => {
                return { ...pet, value: pet.id };
              })}
              onInputChange={(selected) => setPet(selected)}
            />
          </fieldset>
        </div>
      </AppointmentForm.Layout>
    );
  };

  return (
    <div className="vet-page-planned">
      <div className="container">
        <div className="row justify-content-between align-items-end">
          <div>
            <h1 className="title">Planowanie</h1>
            <p>Harmonogram dostępności weterynarza</p>
          </div>

          <Autocomplete
            suggestions={doctors.data.filter((doctor) => doctor.user !== null)}
            getDoctorId={(id) => dispatch(getDoctorVisits(id))}
            reset={() => dispatch(getVisits())}
          />
        </div>

        <div className="content">
          <Scheduler
            locale="pl-PL"
            data={visits?.data?.map((data) => {
              return {
                ...data,
                startDate: data.start,
                endDate: data.end,
                title: data.desc,
                notes: data.comment,
              };
            })}
            height={800}
          >
            <ViewState
              currentDate={currentDate}
              defaultCurrentViewName="Week"
              onCurrentDateChange={(date) => setCurrentDate(date)}
            />
            <Toolbar />

            <WeekView startDayHour={6} />
            <MonthView />

            <EditingState
              onCommitChanges={(data) => {
                commitChanges(data, appointment);
              }}
            />

            <EditRecurrenceMenu />
            <DateNavigator />
            <TodayButton
              messages={{
                today: "Dziś",
              }}
            />

            <Appointments appointmentComponent={TableCell} />
            <AllDayPanel
              messages={{
                allDay: "Cały dzień",
              }}
            />
            <AppointmentForm
              messages={{
                commitCommand: "Zapisz",
                detailsLabel: "Tytuł",
                titleLabel: "Tytuł",
                allDayLabel: "Cały dzień",
                repeatLabel: "Powtarzanie",
                moreInformationLabel: "Szczególy",
                notesLabel: "",
                daily: "Co dziennie",
                weekly: "Co tydzień",
                monthly: "Co miesiąc",
                yearly: "Co roku",
                repeatEveryLabel: "Powtarzaj co",
                daysLabel: "Dzień/Dni",
                endRepeatLabel: "Zakończ powtarzanie",
                never: "Nidgy",
                onLabel: "Po",
                occurrencesLabel: "Powtórzeniu/Powtórzeniach",
                afterLabel: "Data",
              }}
              dateEditorComponent={DateTimeEdit}
              layoutComponent={LayoutComponent}
              onAppointmentDataChange={(data) => setAppointment(data)}
            />
            <DragDropProvider />
            <CurrentTimeIndicator
              shadePreviousCells={true}
              shadePreviousAppointments={true}
              updateInterval={1000}
            />
            <ViewSwitcher switcherComponent={SwitcherComponent} />
          </Scheduler>
        </div>
      </div>
    </div>
  );
};

export default VetPanelPlanned;
