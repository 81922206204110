import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import UserPanelLayout from "../../layouts/userPanel";
import Button from "../../components/Button";
import EditMyDataModal from "../../components/Modals/EditMyDataModal";

const MyData = () => {
  const [showModal, setShowModal] = useState(false);
  const [userData, setUserData] = useState({
    id: null,
    email: null,
    firstName: null,
    lastName: null,
    phone: null,
    username: null,
  });
  const auth = useSelector((state) => state.auth);

  const { id, email, firstName, lastName, phone, username } = auth.user;

  console.log("auth: ", auth);

  useEffect(() => {
    setUserData({
      id,
      email,
      firstName,
      lastName,
      phone,
      username,
    });
  }, [id, email, firstName, lastName, phone, username]);

  return (
    <UserPanelLayout>
      <div className="head">
        <h2 className="title">Moje dane</h2>
        <Button onClick={() => setShowModal(true)}>Edytuj dane</Button>
      </div>
      <section className="my-data-section">
        <div className="name">
          Imię i Nazwisko:{" "}
          <strong>
            {firstName} {lastName}
          </strong>
        </div>
        <div className="mail">
          E-mail: <strong>{email}</strong>
        </div>

        <div className="phone">
          Numer telefonu:{" "}
          {phone ? (
            <a href={`tel:${phone}`}>
              <strong>{phone}</strong>
            </a>
          ) : (
            <strong>Brak numeru telefonu</strong>
          )}
        </div>
      </section>
      {showModal && (
        <EditMyDataModal
          userData={userData}
          close={() => setShowModal(false)}
        />
      )}
    </UserPanelLayout>
  );
};
export default MyData;
