import React from "react";
import PropTypes from "prop-types";

const HistoryItem = ({ date, description, summary }) => {
  const formatedDate = date.split(" ");
  return (
    <div className="history__item">
      <div className="history__item__top">
        <div className="history__item__top__date">
          <strong>
            {formatedDate[0]}, godz. {formatedDate[1]}
          </strong>
        </div>
        <div className="history__item__top__description">{description}</div>
      </div>

      <div className="history__item__bottom">
        <div className="history__item__bottom__review">Opinia weterynarza</div>
        <div className="history__item__bottom__review__description">
          <strong>{summary}</strong>
        </div>
      </div>
    </div>
  );
};

HistoryItem.propTypes = {
  date: PropTypes.string,
  description: PropTypes.string,
  summary: PropTypes.string.isRequired,
};

HistoryItem.defaultProps = {
  summary: "Brak podsumowaia",
};

export default HistoryItem;
