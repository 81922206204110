import { articlesApi } from "../api/ArticlesApi";
import { errorParser } from "../api/ErrorParser";
import * as types from "./ArticlesConstants";

export const fetchArticles = () => ({
  type: types.FETCH_ARTICLES,
});

export const fetchArticle = (id) => ({
  type: types.FETCH_ARTICLE,
  id: id,
});

export const saveArticles = (data) => ({
  type: types.FETCH_ARTICLES_SUCCESS,
  payload: data,
});

export const saveArticle = (data) => ({
  type: types.FETCH_ARTICLE_SUCCESS,
  payload: data,
});

export const errorArticles = (errorMessage) => ({
  type: types.FETCH_ARTICLES_FAILURE,
  payload: errorMessage,
});

export const errorArticle = (errorMessage, id) => ({
  type: types.FETCH_ARTICLE_FAILURE,
  payload: errorMessage,
  id: id,
});

export const getArticles = () => (dispatch) => {
  dispatch(fetchArticles());
  articlesApi
    .get()
    .then(async (res) => {
      dispatch(saveArticles(res.data));
    })
    .catch((err) => {
      dispatch(errorArticles(errorParser.parseError(err).message));
    });
};

export const getArticleById = (id) => (dispatch) => {
  dispatch(fetchArticle(id));
  articlesApi
    .getSingle(id)
    .then(async (res) => {
      dispatch(saveArticle(res.data));
    })
    .catch((err) => {
      dispatch(errorArticle(errorParser.parseError(err).message, id));
    });
};
