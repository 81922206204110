import React, { useState, useContext, useEffect } from "react";
import { RemindPasswordContext } from "../../contexts/modals";
import Modal from "react-modal";
import Button from "../Button";
import Icon from "../Icon";
import InputComponent from "../Input";
import { strings } from "../../values/Strings";
import { validateEmail } from "../../utils/utils";
import { useDispatch } from "react-redux";
import { forgotPassword } from "../../actions/UserActions";
import { clearErrorMessage } from "../../actions/UserActions";
import { useSelector } from "react-redux";

const RemindPassword = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const { setShowRemindPasswordModal } = useContext(RemindPasswordContext);

  const remindPasswordHandler = () => {
    if (email.length === 0) {
      setError(strings.error_field_empty);
      return;
    }
    if (!validateEmail(email)) {
      setError(strings.error_email_validation);
      return;
    }
    dispatch(forgotPassword(email));
  };

  useEffect(() => {
    auth.showSendMailModal && setShowRemindPasswordModal(false);
  }, [auth.showSendMailModal, setShowRemindPasswordModal]);

  useEffect(() => {
    if (auth.errorMessage.length) {
      setError(auth.errorMessage);
    }
  }, [dispatch, auth]);

  useEffect(() => {
    dispatch(clearErrorMessage());
    setError("");
  }, [dispatch]);

  return (
    <Modal
      isOpen={true}
      className="modal modal--auth remind-password"
      overlayClassName="modal-overlay"
      ariaHideApp={false}
      onRequestClose={() => setShowRemindPasswordModal(false)}
    >
      <div className="modal-Header">
        <h2>Przypomnij hasło</h2>
        <Button
          onClick={() => setShowRemindPasswordModal(false)}
          className="transparent padding-0"
        >
          <Icon name="close" color="white" />
        </Button>
      </div>

      <div className="modal-content">
        <InputComponent
          placeholder="E-mail"
          value={email}
          type="email"
          onChange={(e) => setEmail(e.target.value)}
        />
        {error && <span className="modal-form__error">{error}</span>}
        <Button
          className="send-btn"
          onClick={remindPasswordHandler}
          loading={auth.loading}
        >
          Wyślij
        </Button>
      </div>
    </Modal>
  );
};

export default RemindPassword;
