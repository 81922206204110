import API from "./ApiConfig";

export const notificationsApi = {
  getDoctorNotifications,
  updateNotification,
  getNotifications,
};

function getDoctorNotifications(id, sort) {
  return API.get(`/doctors/${id}/notifications`, {
    params: {
      _sort: sort,
    },
  });
}

function updateNotification(doctorId, notificationId, data) {
  return API.put(`/doctors/${doctorId}/notifications/${notificationId}`, data);
}

function getNotifications() {
  return API.get("/notifications");
}
