export const FETCH_PETS = "FETCH_PETS";
export const FETCH_PETS_SUCCESS = "FETCH_PETS_SUCCESS";
export const FETCH_PETS_FAILURE = "FETCH_PETS_ERROR";

export const FETCH_PETS_TYPES = "FETCH_PETS_TYPES";
export const FETCH_PETS_TYPES_SUCCESS = "FETCH_PETS_TYPES_SUCCESS";
export const FETCH_PETS_TYPES_FAILURE = "FETCH_PETS_TYPES_ERROR";

export const ADD_PET = "ADD_PET";
export const ADD_PET_FAILURE = "ADD_PET_ERROR";

export const UPDATE_PET = "UPDATE_PET";
export const UPDATE_PET_FAILURE = "UPDATE_PET_ERROR";

export const FETCH_STATUS_SUCCESS = "FETCH_STATUS_SUCCESS";

export const FETCH_PET_HISTORY = "FETCH_PET_HISTORY";
export const FETCH_PET_HISTORY_SUCCESS = "FETCH_PET_HISTORY_SUCCESS";
export const FETCH_PET_HISTORY_FAILURE = "FETCH_PET_HISTORY_ERROR";
