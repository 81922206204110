import strings from "../values/Strings";

const validateEmail = (val) => {
  let reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  return reg.test(val);
};

//password between 6 to 20 characters which contain at least one numeric digit, one uppercase and one lowercase letter
const validatePassword = (val) => {
  let reg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
  return reg.test(val);
};

//check object is Empty
const isEmpty = ({ obj }) => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

const getUrlToImage = (urlImage) => {
  return strings.API_URL.substring(0, strings.API_URL.length - 1) + urlImage;
};

const formatPrice = (price) => {
  return price.toString().replace(".", ",");
};

const formatPromotion = (percent) => {
  return "-" + percent * 100 + "%";
};

const formatLongText = (text, maxLength) => {
  if (text?.length > maxLength) {
    return `${text.slice(0, maxLength)}…`;
  }
  return text;
};

const getAvatar = (data) => {
  if (data?.avatar?.url) {
    return getUrlToImage(data.avatar.url);
  } else {
    return require("../assets/image/logo_pupile.png");
  }
};

// get week day no, starts with monday
const getDayNo = (date) => {
  let newDate = new Date(date);
  return newDate.getDay() === 0 ? 6 : newDate.getDay() - 1;
};

const getMapLnk = (loc) => {
  return `https://www.google.pl/maps/?q=${loc?.split(" ").join("")}`;
};

function dynamicSort(property) {
  const sortOrder = property[0] === "-" ? -1 : 1;
  if (property[0] === "-") {
    property = property.substr(1);
  }
  return function (a, b) {
    var result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
}

function firstUpperCase(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export {
  validateEmail,
  validatePassword,
  isEmpty,
  getUrlToImage,
  formatPrice,
  formatPromotion,
  getAvatar,
  dynamicSort,
  firstUpperCase,
  getDayNo,
  formatLongText,
  getMapLnk,
};
