import React, { useState, useEffect } from "react";

import { func } from "prop-types";

import { useDispatch, useSelector } from "react-redux";

import { addPet, getPetsTypes, updatePet } from "../../../actions/PetsActions";

import Button from "../../../components/Button";
import Modal from "react-modal";
import InputComponent from "../../../components/Input";
import Select from "../../../components/FormComponents/Select";
import Radio from "../../../components/FormComponents/Radio";
import RangeSlider from "../../../components/FormComponents/Range";
import CustomSwitch from "../../../components/FormComponents/Switch";
import FileUploader from "../../../components/FormComponents/FileUpload";

import { originOptions, radioOptions } from "../../../values/options";
import { getUrlToImage } from "../../../utils/utils";

import defaultPetImg from "../../../assets/image/logo_pupile.png";

const NewPetModal = ({ hideModal, showStatusModal, currentPet }) => {
  const dispatch = useDispatch();

  const petsTypes = useSelector((state) => state.pets.types);

  const petTypesNewKeys = [
    ...petsTypes?.map(({ id: value, type: name, ...rest }) => ({
      value,
      name,
      ...rest,
    })),
  ];

  useEffect(() => {
    dispatch(getPetsTypes());
  }, [dispatch]);

  const year = new Date().getFullYear();

  const defaultData = {
    name: "",
    mikrochipId: "",
    pet_type: currentPet?.pet_type?.id ? currentPet.pet_type.id : 1,
    species: "",
    origin: originOptions[0].value,
    sex: radioOptions[0].value,
    yearBirth: year - 20,
    weight: 22,
    neutered: true,
    outgoing: true,
  };

  const [formData, setFormData] = useState(defaultData);

  const [image, setImage] = useState(
    currentPet?.avatar?.url ? currentPet.avatar.url : defaultPetImg
  );

  const [error, setError] = useState({});

  const updaeFormData = (key, val) => {
    setFormData({ ...formData, [key]: val });
    if (val?.length === 0) {
      setError({ ...error, [key]: true });
    } else {
      setError({ ...error, [key]: false });
    }
  };

  useEffect(() => {
    currentPet?.id && setFormData(currentPet);
  }, [currentPet]);

  const addPetFn = () => {
    let formDataItem = {};
    Object.keys(formData).forEach((item, i) => {
      if (formData[item]?.length === 0) {
        formDataItem[item] = true;
      } else {
        formDataItem[item] = false;
      }
    });
    setError(formDataItem);

    if (
      formDataItem.name === false &&
      formDataItem.mikrochipId === false &&
      formDataItem.species === false
    ) {
      dispatch(
        currentPet?.id
          ? updatePet(currentPet.id, formData, image)
          : addPet(formData, image)
      );
      setFormData(defaultData);
      showStatusModal();
      hideModal();
    }
  };

  return (
    <Modal
      isOpen={true}
      className="add-new-pet modal"
      overlayClassName="modal-overlay"
      ariaHideApp={false}
      onRequestClose={hideModal}
    >
      <div className="modal-header row justify-content-between align-items-center">
        <h2>
          {currentPet?.id ? "Zaktualizuj dane pupila" : "Dodaj nowego pupila"}
        </h2>
        <div>
          <Button className="transparent" onClick={hideModal}>
            Anuluj
          </Button>
          <Button className="transparent" onClick={addPetFn}>
            {currentPet?.id ? "Zaktualizuj" : "Zapisz dane"}
          </Button>
        </div>
      </div>

      <div className="modal-content">
        <form className="row no-gutters justify-content-between" method="post">
          <div className="left col-11">
            <fieldset>
              <InputComponent
                placeholder="Imię zwierzaka"
                name="name"
                value={formData.name}
                onChange={(e) => {
                  updaeFormData("name", e.target.value);
                }}
              />
              {error.name && (
                <span className="error">Proszę wpisz imię pupila.</span>
              )}
            </fieldset>

            <fieldset>
              <InputComponent
                placeholder="Numer mikroczip / PESEL"
                name="mikrochipId"
                value={formData.mikrochipId}
                onChange={(e) => {
                  updaeFormData("mikrochipId", e.target.value);
                }}
              />
              {error.mikrochipId && (
                <span className="error">
                  Proszę wpisz numer mikroczipu lub pesel
                </span>
              )}
            </fieldset>

            <fieldset>
              <Select
                loading={!petTypesNewKeys.length > 0}
                name="pet_type"
                defaultValue={currentPet.id}
                options={petTypesNewKeys}
                onInputChange={(selected) =>
                  updaeFormData("pet_type", selected)
                }
              />
            </fieldset>
            <fieldset>
              <InputComponent
                placeholder="Gatunek"
                name="species"
                value={formData.species}
                onChange={(e) => {
                  updaeFormData("species", e.target.value);
                }}
              />
              {error.species && (
                <span className="error">Proszę podaj rasę.</span>
              )}
            </fieldset>
            <fieldset>
              <Select
                name="origin"
                options={originOptions}
                onInputChange={(selected) => updaeFormData("origin", selected)}
              />
            </fieldset>
            <Radio
              label="Płeć"
              name="sex"
              options={radioOptions}
              onInputChange={(selected) => {
                setFormData({ ...formData, sex: selected });
              }}
            />
          </div>

          <div className="right col-11">
            <fieldset>
              <RangeSlider
                label="Rok urodzenia"
                name="yearBirth"
                min={year - 40}
                max={year}
                onInputChange={(value) => updaeFormData("yearBirth", value)}
              />
            </fieldset>

            <fieldset>
              <RangeSlider
                label="Waga pupila"
                name="weight"
                min={0}
                max={150}
                unit="kg"
                onInputChange={(value) => updaeFormData("weight", value)}
              />
            </fieldset>

            <CustomSwitch
              name="neutered"
              label="Wykastrowany"
              textLeft="Nie"
              textRight="Tak"
              defaultValue={currentPet?.id && currentPet.neutered}
              onInputChange={(value) => updaeFormData("neutered", value)}
            />

            <CustomSwitch
              name="outgoing"
              label="Wychodzący"
              textLeft="Nie"
              textRight="Tak"
              defaultValue={currentPet?.id && currentPet.outgoing}
              onInputChange={(value) => updaeFormData("outgoing", value)}
            />

            <fieldset>
              <FileUploader
                label="Zdjęcie pupila"
                name="petImage"
                img={currentPet?.avatar?.url && getUrlToImage(image)}
                onInputChange={(value) => setImage(value)}
              />
            </fieldset>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default NewPetModal;

NewPetModal.propTypes = {
  hideModal: func,
  showStatusModal: func,
};
