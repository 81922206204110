import API from "./ApiConfig";

export const visitsApi = {
  getVisits,
  getVisit,
  cancelVisit,
  reserveVisit,
  getDoctorVisits,
  updateVisitComment,
};

function getVisits() {
  return API.get("visits/me");
}

function getVisit(id) {
  return API.get(`visits/${id}`);
}

function reserveVisit(data) {
  return API.post("/visits", data);
}

function cancelVisit(id) {
  return API.delete(`visits/${id}`);
}

function getDoctorVisits(userId) {
  return API.get(`/doctors/${userId}/visits`);
}

function updateVisitComment(visitID, comment) {
  return API.put(`/visits/${visitID}`, { comment });
}
