import React, { useState, useRef, useEffect } from "react";
import { NavLink, useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../actions/UserActions";
import {
  getDoctorNotifications,
  updateNotification,
} from "../../actions/NotificationsActions";
import { io } from "socket.io-client";

import Icon from "../Icon";
import Button from "../Button";
import routes from "../../routes";
import logo from "../../assets/image/logo_pupile.png";
import HeaderLayout from "../../layouts/header";
import Notifications from "./Notifications";

import useOutsideClick from "../../hooks/clickOutside";

import { shape, bool, string, number } from "prop-types";

const SchedulerHeader = ({ user, isReceptionist, isDoctor }) => {
  const dispatch = useDispatch();

  const [showMenu, setShowMenu] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [unreaded, setUnreaded] = useState([]);
  const stateNotifications = useSelector((state) => state.notifications);

  useEffect(() => {
    user?.doctors[0]?.id &&
      dispatch(getDoctorNotifications(user?.doctors[0]?.id, "id:DESC"));
  }, [dispatch, user.doctors]);

  useEffect(() => {
    stateNotifications && setNotifications(stateNotifications.data);
    setUnreaded(
      stateNotifications.data.filter((notification) => notification.opened)
    );
  }, [stateNotifications]);

  const { vet } = routes;

  const ref = useRef();
  const history = useHistory();

  useOutsideClick(ref, () => {
    if (showMenu) setShowMenu(false);
  });

  const onLogout = () => {
    dispatch(logout());
    history.push("/");
  };

  const socket = io.connect("https://pupile-api.stage.etd24.pl");
  socket.on("askForUserId", () => {
    user?.doctors[0]?.id && socket.emit("userIdReceived", user?.doctors[0]?.id);
  });
  socket.on("notification", (notification) => {
    setNotifications([notification, ...notifications]);
    setUnreaded([
      ...unreaded,
      [notification].filter((notification) => notification.opened),
    ]);
  });

  const handleDisableNotification = (id) => {
    dispatch(updateNotification(user?.doctors[0]?.id, id, { opened: false }));
  };

  return (
    <HeaderLayout className="scheduler-header">
      <div className="col-8 scheduler-header__content--left">
        <Link className="" to="/">
          <img src={logo} alt="pupile-logo" />
        </Link>
        <h4>
          Panel {isReceptionist ? "recepcjonisty" : isDoctor && "weterynarza"}
        </h4>
      </div>
      <nav className="col-8 scheduler-header__content--middle">
        <ul>
          <li>
            <NavLink to={`${vet}/wizyty`}>Wizyty</NavLink>
          </li>
          <li>
            <NavLink to={`${vet}/planowanie`}>Planowanie</NavLink>
          </li>
          {isReceptionist && (
            <li>
              <NavLink to={`${vet}/employees`}>Pracownicy</NavLink>
            </li>
          )}
        </ul>
      </nav>

      <div className="col-8 scheduler-header__content--right">
        <Button
          className="transparent bell"
          icon="bell"
          iconColor={showNotification ? "orange" : "black"}
          onClick={() => setShowNotification(!showNotification)}
        >
          {unreaded.length > 0 && (
            <span className="number">{unreaded.length}</span>
          )}
        </Button>
        {showNotification && (
          <Notifications
            notifications={notifications}
            getId={(notificationId) =>
              handleDisableNotification(notificationId)
            }
            close={() => setShowNotification(false)}
          />
        )}

        <Button
          className="transparent settings"
          icon="settings"
          iconColor="disabled-icons"
        />

        <div
          ref={ref}
          className={`user ${showMenu ? "active" : ""}`}
          onClick={() => setShowMenu(!showMenu)}
        >
          {/* TODO add avatar when api ready */}
          <Icon name="person" />
          <div>
            <div className="name">
              <div>
                {user?.firstName} {user?.lastName}
                <br />
                {user?.username && <>[{user?.username}]</>}
              </div>
              <Icon name="arrow_down" />
            </div>
          </div>
        </div>

        {
          <div className={`account-menu ${showMenu ? "active" : ""}`}>
            <ul>
              <li>
                <Button className="transparent" onClick={() => onLogout()}>
                  Wyloguj się
                </Button>
              </li>
            </ul>
          </div>
        }
      </div>
    </HeaderLayout>
  );
};

export default SchedulerHeader;

SchedulerHeader.propTypes = {
  user: shape({
    blocked: bool,
    confirmed: bool.isRequired,
    created_at: string.isRequired,
    email: string.isRequired,
    id: number.isRequired,
    provider: string,
    role: number.isRequired,
    updated_at: string.isRequired,
    username: string,
  }).isRequired,
  isReceptionist: bool.isRequired,
  isDoctor: bool.isRequired,
};
