import React, { useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import Button from "../../components/Button";
import Icon from "../../components/Icon";
import { Link } from "react-router-dom";
import ConfirmModal from "../../components/Modals/ConfirmModal";

import { shape, bool, string, number, func } from "prop-types";
import { removeDoctor } from "../../actions/DoctorsActions";

const EmployeeBox = ({ data, onEdit }) => {
  const dispatch = useDispatch();

  const doctors = useSelector((state) => state.doctors.doctors);

  const { title, user, place } = data;

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const fullName = useMemo(() => {
    return `${title}. ${user?.firstName || "-"} ${user?.lastName || "-"} `;
  }, [user, title]);

  return (
    <div className="employee-box">
      {showConfirmModal && (
        <ConfirmModal
          loading={doctors?.loading}
          error={doctors?.error}
          hideModal={() => setShowConfirmModal(false)}
          onSubmit={() => dispatch(removeDoctor(data.id))}
          question={"Na pewno chcesz usunąć lekarza?"}
        />
      )}

      <div className="row">
        <div className="col-16">
          <div className="employee-box__content">
            <div className="employee-box__head">
              <h4>{fullName}</h4>
            </div>
            <ul>
              <li>
                <Icon name="phone" color="orange" /> {user?.phone || "-"}
              </li>
              <li>
                <Icon name="envelope" color="orange" /> {user?.email || "-"}
              </li>
              <li>
                <Icon name="person" color="orange" />
                {user?.firstName || "-"} {user?.lastName || "-"}
                {user?.username && <span>&nbsp; - [{user?.username}]</span>}
              </li>
              <li>
                <Icon name="marker" color="orange" /> {place?.name || "-"}
              </li>
            </ul>
          </div>
        </div>
        <div className="col-8">
          <div className="employee-box__actions">
            <Button onClick={() => onEdit(data)} className="transparent">
              <Icon name="edit" color="orange" />
              EDYTUJ
            </Button>
            <Button
              onClick={() => setShowConfirmModal(true)}
              className="transparent"
            >
              <Icon name="delete" color="orange" />
              USUŃ
            </Button>
            <hr />
            <Link
              className="button transparent"
              to={`/panel-weterynarza/wizyty/employee=${user?.id}`}
            >
              <Icon name="calendar" color="orange" /> WIZYTY
            </Link>
            <Link
              className="button transparent"
              to={`/panel-weterynarza/wizyty/employee=${user?.id}`}
            >
              <Icon name="clipboard" color="orange" /> PLANOWANIE
            </Link>

            <Button onClick={() => onEdit(data, true)} className="transparent">
              <Icon name="clock" color="orange" />
              GODZINY PRACY
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeBox;
EmployeeBox.propTypes = {
  data: shape({
    created_at: string.isRequired,
    displayPhone: string,
    hidden: bool,
    id: number.isRequired,
    place: shape({}),
    title: string,
    updated_at: string.isRequired,
    user: shape({}),
    workingHours: shape({}),
  }).isRequired,
  onEdit: func,
};
