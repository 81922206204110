import React from "react";
import moment from "moment";
import strings from "../../values/Strings";

const Notifications = ({ notifications, active, getId, close }) => {
  return (
    <>
      <div className="notifications">
        <table>
          <thead>
            <tr>
              <th>Wewnętrzna komunikacja</th>
              <th>Nowe wizyty</th>
              <th>Anulowane wizyty</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Brak skierowań</td>
              <td>
                {notifications.map((notification) => {
                  const date = moment(notification?.visit?.start)
                    .format(strings.hour__day_name__full_date)
                    .split(" ");
                  return (
                    <div
                      key={notification.id}
                      className={`notification${
                        notification.opened ? " click" : ""
                      }`}
                      onClick={
                        notification.opened
                          ? () => getId(notification.id)
                          : null
                      }
                    >
                      <div className="info">
                        <div className="title">{notification.title}</div>
                        <div className="date">
                          <div className="hour">{date[0]}</div> -
                          <div className="day">{date[1]}</div> -
                          <div className="date">{date[2]}</div>
                        </div>
                      </div>
                      {notification.opened && <div className="readed"></div>}
                    </div>
                  );
                })}
              </td>
              <td>
                <div>Brak anulowanych wizyt</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div onClick={close} className="background-blur"></div>
    </>
  );
};

export default Notifications;
