export const LOADING = "LOADING";
export const LOGGED_IN = "LOGGED_IN";
export const REGISTER = "REGISTER";
export const ERROR_LOG_IN = "ERROR_LOG_IN";
export const ERROR_REGISTER = "ERROR_REGISTER";
export const LOGOUT = "LOGOUT";
export const CLEAR_ERROR_MESSAGE = "CLEAR_ERROR_MESSAGE";

//UPDATE EMAIL
export const UPDATE_EMIAL_FAILURE = "UPDATE_EMAIL_FAILURE";
export const UPDATE_EMIAL_SUCCESS = "UPDATE_EMAIL_SUCCESS";

//GET USER PROFILE
export const GET_USER_PROFILE_FAILURE = "GET_USER_PROFILE_FAILURE";
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";

//CHANGE PASSWORD
export const EMAIL_SENT_FAILURE = "EMAIL_SENT_FAILURE";
export const EMAIL_SENT_SUCCESS = "EMAIL_SENT_SUCCESS";

// RESET PASSWORD
export const ERROR_RESET_PASSWORD = "ERROR_RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
