export const FETCH_DOCTOR_NOTIFICATIONS = "FETCH_DOCTOR_NOTIFICATIONS";
export const FETCH_DOCTOR_NOTIFICATIONS_SUCCESS =
  "FETCH_DOCTOR_NOTIFICATIONS_SUCCESS";
export const FETCH_DOCTOR_NOTIFICATIONS_FAILURE =
  "FETCH_DOCTOR_NOTIFICATIONS_ERROR";

export const UPDATE_DOCTOR_NOTIFICATION = "UPDATE_DOCTOR_NOTIFICATION";
export const UPDATE_DOCTOR_NOTIFICATION_SUCCESS =
  "UPDATE_DOCTOR_NOTIFICATION_SUCCESS";
export const UPDATE_DOCTOR_NOTIFICATION_FAILURE =
  "UPDATE_DOCTOR_NOTIFICATION_ERROR";
