import React from "react";
import { LightgalleryItem } from "react-lightgallery";
import { string } from "prop-types";

const PhotoItem = ({ image, thumb, group }) => (
  <div className="col-8">
    <LightgalleryItem group={group} src={image} thumb={thumb}>
      <img src={image} style={{ width: "100%" }} alt="" />
    </LightgalleryItem>
  </div>
);

export default PhotoItem;

PhotoItem.propTypes = {
  image: string,
  thumb: string,
  group: string,
};
