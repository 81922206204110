import React from "react";

import { useSelector } from "react-redux";

import { shape, func } from "prop-types";

import { getUrlToImage, getMapLnk } from "../../utils/utils";

import Modal from "react-modal";

import Icon from "../Icon";
import Gallery from "../Gallery";
import BookVisitForm from "./BookVisitForm";
import VisitModal from "./VisitModal";
import moment from "moment";

const BookVisit = ({ data, onHideForm }) => {
  const visits = useSelector((state) => state.visits);

  const { name, cover, address, desc, logo, location, spec } = data;

  if (visits?.success) {
    return <VisitModal onClose={onHideForm} />;
  }

  const {
    fridayFrom,
    fridayTo,
    mondayFrom,
    mondayTo,
    saturdayFrom,
    saturdayTo,
    sundayFrom,
    sundayTo,
    thursdayFrom,
    thursdayTo,
    tuesdayFrom,
    tuesdayTo,
    wednesdayFrom,
    wednesdayTo,
  } = data.openHours;

  return (
    <Modal
      isOpen={true}
      className="container book-visit"
      overlayClassName="modal-overlay"
      ariaHideApp={false}
      onRequestClose={onHideForm}
    >
      <div className="row">
        <div className="col-14 book-visit__left">
          <div>
            <section className="book-visit__content">
              <div className="book-visit__content__address">
                <h2>{name}</h2>
                <h5>
                  Specjalizacj{spec?.length > 1 ? "e" : "a"}:{" "}
                  {spec.length > 0
                    ? spec.map((item, i) => (
                        <span key={i}>
                          {item.type}
                          {i !== spec.length - 1 && ", "}
                        </span>
                      ))
                    : "Brak danych"}
                </h5>
                <address>
                  ul. {address?.street}
                  <br />
                  {address?.zip} {address?.city} <br />
                  <a
                    className="link-with-icon"
                    rel="noopener noreferrer"
                    href={getMapLnk(location)}
                    target="_blank"
                  >
                    <span>Nawiguj</span> <Icon name="navigate" />
                  </a>
                </address>
              </div>

              {logo?.url && (
                <picture>
                  <img
                    src={getUrlToImage(logo.url)}
                    alt={logo?.alternativeText || "logo kliniki"}
                  />
                </picture>
              )}
            </section>

            <article>
              <p>{desc}</p>

              <table className="open-hours-table">
                <thead>
                  <tr>
                    {moment.weekdaysShort(true).map((day, index) => (
                      <th key={index}>{day}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div>
                        <span>{mondayFrom}</span>
                        <Icon color="orange" name="arrow_down" />
                        <span>{mondayTo}</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>{tuesdayFrom}</span>
                        <Icon color="orange" name="arrow_down" />
                        <span>{tuesdayTo}</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>{wednesdayFrom}</span>
                        <Icon color="orange" name="arrow_down" />
                        <span>{wednesdayTo}</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>{thursdayFrom}</span>
                        <Icon color="orange" name="arrow_down" />
                        <span>{thursdayTo}</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>{fridayFrom}</span>
                        <Icon color="orange" name="arrow_down" />
                        <span>{fridayTo}</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>{saturdayFrom}</span>
                        <Icon color="orange" name="arrow_down" />
                        <span>{saturdayTo}</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>{sundayFrom}</span>
                        <Icon color="orange" name="arrow_down" />
                        <span>{sundayTo}</span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </article>
          </div>

          <section className="book-visit__gallery">
            {cover?.length > 0 && (
              <Gallery data={cover?.map((el) => getUrlToImage(el.url))} />
            )}
          </section>
        </div>
        <div className="col-10 book-visit__right">
          <aside>
            <BookVisitForm />
          </aside>
        </div>
      </div>
    </Modal>
  );
};

export default BookVisit;

BookVisit.propTypes = {
  data: shape({}),
  onHideForm: func,
};
